import { cloneDeep } from 'lodash';
import { useEffect, useState, useMemo } from 'react';
import { useSnapshot } from 'valtio';
import { TacticStatus, Channel } from 'basics/enums/maya.enums';
import useGetAllTacticsQuery from 'basics/graphql/queries/getAllTactics';
import useGetTargetListByCepIdQuery from 'basics/graphql/queries/getTargetListByCepId';
import { CepStateType } from 'basics/types/maya.types';
import { FieldValue } from 'components/MiViewScreenFilterMenu/MiViewScreenFilterMenu.types';
import { Target, Tactic } from 'generated/maya.types';
import cepState from 'states/cep.states';

const useMiViewScreenTactic = () => {
  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const [targets, setTargets] = useState<Target[]>([]);
  const [tactics, setTactics] = useState<Tactic[]>([]);
  const [nonChildTactics, setNonChildTactics] = useState<Tactic[]>([]);
  const [targetFieldValues, setTargetFieldValues] = useState<FieldValue[]>([]);
  const [selectedTarget, setSelectedTarget] = useState<Target | null>(null);
  const [screenLoading, setScreenLoading] = useState<boolean>(true);

  const cepId = cepStateValue.cep?._id ? cepStateValue.cep._id : null;
  const cepTitle = cepStateValue.cep?.title ? cepStateValue.cep.title : null;
  const { data, loading } = useGetTargetListByCepIdQuery(cepId);

  const queryTacticFilters = useMemo(
    () => ({
      cep: cepId,
      status: [TacticStatus.DRAFT, TacticStatus.APPROVED],
      channel: Object.values(Channel).filter((channel) => channel !== Channel.group),
    }),
    [cepId],
  );

  const { data: tacticsData, loading: tacticsLoading } = useGetAllTacticsQuery(undefined, queryTacticFilters);

  const updateFilterField = (fieldName: string, fieldValue: string) => {
    const target = targets.find((targetItem) => targetItem._id === fieldValue);
    setSelectedTarget(target || null);
  };

  const clearFilterField = () => {
    setSelectedTarget(null);
  };

  const transformTargetsToFieldValue = (targetsValues: Target[]): FieldValue[] => {
    return targetsValues.map((target) => ({
      id: target._id,
      value: `${target.lastName}${target.firstName && target.lastName ? ', ' : ''}${target.firstName}`,
      filtered: true,
      count: 2,
    }));
  };

  useEffect(() => {
    if (!loading) {
      const clonedTargets = cloneDeep(data.filter((targetItem) => targetItem.firstName || targetItem.lastName));
      const sortedTargets = clonedTargets.sort((prev, next) => `${prev.lastName || prev.firstName}`
        .localeCompare(`${next.lastName || next.firstName}`));
      setTargets(sortedTargets);
    }

    if (!tacticsLoading) {
      const clonedTactics = cloneDeep(tacticsData);
      const sortedTacticsData = clonedTactics.sort((prev, next) => Number(prev.actionOrder) - Number(next.actionOrder));
      setTactics(sortedTacticsData);
      setNonChildTactics(sortedTacticsData?.filter((tactic) => !tactic.parentTactic));
      setScreenLoading(tacticsLoading);
    }
  }, [data, loading, tacticsData, tacticsLoading]);

  useEffect(() => {
    if (targets.length) {
      setTargetFieldValues(transformTargetsToFieldValue(targets));
    }
  }, [tactics, targets]);

  return { tactics,
    nonChildTactics,
    targets,
    targetFieldValues,
    cepTitle,
    selectedTarget,
    updateFilterField,
    clearFilterField,
    screenLoading };
};

export default useMiViewScreenTactic;
