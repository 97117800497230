import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export type QueryParams = {
  id: string;
  year: string;
  error: string;
  eventId: string;
  parentId: string;
  cepSourceId: string;
};

export const useQueryParams = (): QueryParams => {
  const { search } = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(search);
    const queryParams: Record<string, string | undefined> = {};

    params.forEach((value, key) => {
      queryParams[key] = value;
    });

    return queryParams as QueryParams;
  }, [search]);
};
