import { addDays } from 'date-fns';
import { Channel, BiogenFunction } from 'basics/enums/maya.enums';
import { DropMaterialsOptionType } from 'basics/types/dropMaterials.types';
import { KeyMessagesOptionType } from 'basics/types/keyMessages.types';
import { EventState } from 'basics/types/maya.types';
import { formatUTCDate } from 'basics/utils/date.utils';
import { transformInputDateUTC } from 'basics/utils/dates/dates.utils';
import { Channel as ServerChannel, BulkUpdateTacticInput, CustomerEngagementPlanning, Tactic, TacticInput } from 'generated/maya.types';
import { YodaFieldValue } from 'yoda-ui/yodaForm/yodaForm.types';

export const tacticToBE = (
  data: YodaFieldValue,
  cepId: string | undefined,
  keyMessagesOptions: KeyMessagesOptionType[],
  dropMaterialsOptions: DropMaterialsOptionType[],
): Partial<TacticInput> => {
  const keyMessage = keyMessagesOptions.filter(({ id }) => id === data.keyMessage);
  const dropMaterial = dropMaterialsOptions.filter(({ id }) => id === data.dropMaterial);

  return {
    title: data.title,
    channel: data.channel,
    productionOwner: data.productionOwner,
    ...transformInputDateUTC(data.date),
    cep: cepId,
    executingTeam: data.executingTeam,
    description: data.description,
    mandatory: data.mandatory,
    includeConsent: data.includeConsent,
    previousTactic: data.tacticGroup || data.previousTactic || null,
    parentTactic: data.parentTactic || null,
    assetId: data.assetId,
    assetName: data.assetName,
    callToAction: data.callToAction,
    clickUrl: data.clickUrl,
    reachMetric: data.reachMetric,
    reachValue: parseInt(data?.reachValue, 10) || undefined,
    engagementMetric: data.engagementMetric,
    engagementValue: parseInt(data?.engagementValue, 10) || undefined,
    keyMessage: keyMessage.length ? {
      id: keyMessage[0].id.toString(),
      name: keyMessage[0].label,
    } : undefined,
    dropMaterial: dropMaterial.length ? {
      id: dropMaterial[0].id,
      name: dropMaterial[0].label,
    } : {
      id: '',
      name: '',
    },
    parentEvent: data.parentEvent || false,
    approvedDocumentId: data.approvedDocumentId,
    eventId: data.eventId,
    actionOrder: !Number.isNaN(parseInt(data?.actionOrder, 10)) ? parseInt(data?.actionOrder, 10) : undefined,
    destinationUrl: data.destinationUrl,
    isKeyPerformanceIndicator: data.isKeyPerformanceIndicator,
  };
};

export const tacticToBulkUpdateInput = (
  tactic: Tactic,
  cepId: string | undefined,
): BulkUpdateTacticInput => {
  return {
    _id: tactic._id,
    title: tactic?.title,
    channel: Channel[tactic.channel as unknown as keyof typeof Channel],
    productionOwner: tactic?.productionOwner || '',
    description: tactic?.description || '',
    cep: cepId,
    executingTeam: tactic.executingTeam,
    mandatory: tactic?.mandatory || false,
    includeConsent: tactic?.includeConsent !== undefined ? tactic?.includeConsent : true,
    previousTactic: tactic?.previousTactic?._id || null,
    parentTactic: tactic?.parentTactic?._id || null,
    assetId: tactic?.assetId || '',
    assetName: tactic?.assetName || '',
    callToAction: tactic?.callToAction || undefined,
    clickUrl: tactic?.clickUrl || '',
    reachMetric: tactic?.reachMetric,
    reachValue: tactic?.reachValue,
    engagementMetric: tactic?.engagementMetric,
    engagementValue: tactic?.engagementValue,
    keyMessage: tactic?.keyMessage || undefined,
    dropMaterial: tactic?.dropMaterial || undefined,
    parentEvent: tactic?.parentEvent || false,
    approvedDocumentId: tactic?.approvedDocumentId || '',
    eventId: tactic?.eventId || '',
    actionOrder: tactic?.actionOrder,
    destinationUrl: tactic?.destinationUrl || '',
    isKeyPerformanceIndicator: tactic?.isKeyPerformanceIndicator || false,
  };
};

export const tacticGroupToBE = (
  title: string,
  cepId: string | undefined,
): Partial<TacticInput> => {
  return {
    title,
    cep: cepId,
    channel: Channel.group,
    executingTeam: BiogenFunction.Commercial,
  };
};

export const tacticToForm = (
  tactic: Tactic | undefined | null,
  cep: Partial<CustomerEngagementPlanning> | undefined | null,
) => {
  const previousTactic = tactic?.previousTactic as Tactic;
  const parentTactic = tactic?.parentTactic as Tactic;
  const dateDefaultValue = tactic?.dateStart
    ? formatUTCDate(tactic?.dateStart, tactic?.dateEnd)
    : formatUTCDate(cep?.dateStart);

  return {
    title: tactic?.title || '',
    channel: tactic?.channel ? Channel[tactic.channel as unknown as keyof typeof Channel] : undefined,
    productionOwner: tactic?.productionOwner || '',
    date: dateDefaultValue,
    description: tactic?.description,
    cep: tactic?.cep || {},
    executingTeam: tactic?.executingTeam || '',
    mandatory: tactic?.mandatory || false,
    includeConsent: tactic?.includeConsent !== undefined ? tactic?.includeConsent : true,
    previousTactic: previousTactic?._id || null,
    parentTactic: parentTactic?._id || null,
    assetId: tactic?.assetId,
    assetName: tactic?.assetName,
    callToAction: tactic?.callToAction,
    clickUrl: tactic?.clickUrl,
    reachMetric: tactic?.reachMetric,
    reachValue: tactic?.reachValue?.toString(),
    engagementMetric: tactic?.engagementMetric,
    engagementValue: tactic?.engagementValue?.toString(),
    keyMessage: tactic?.keyMessage?.id || '',
    dropMaterial: tactic?.dropMaterial?.id || '',
    parentEvent: tactic?.parentEvent || false,
    approvedDocumentId: tactic?.approvedDocumentId || '',
    eventId: tactic?.eventId || '',
    actionOrder: tactic?.actionOrder?.toString(),
    destinationUrl: tactic?.destinationUrl || '',
    isKeyPerformanceIndicator: tactic?.isKeyPerformanceIndicator || false,
  };
};

export const eventToTactic = (cep: CustomerEngagementPlanning, tactic: Tactic, event: EventState): BulkUpdateTacticInput => {
  const tacticInput = tacticToBulkUpdateInput(tactic, cep._id);
  const { channel } = tactic;

  if (channel === ServerChannel.broadcastEmail) {
    return {
      ...tacticInput,
      dateStart: cep.dateStart,
      dateEnd: cep.dateEnd,
      assetName: event.assetName,
      clickUrl: event.eventURL,
      executingTeam: BiogenFunction.Commercial,
    };
  }

  if (channel === ServerChannel.webLogIn) {
    return {
      ...tacticInput,
      dateStart: cep.dateStart,
      dateEnd: cep.dateEnd,
      description: event.assetName,
      destinationUrl: event.eventURL,
      executingTeam: BiogenFunction.Commercial,
    };
  }

  if (channel === ServerChannel.virtualEvent || channel === ServerChannel.f2fEvent || channel === ServerChannel.hybridEvent) {
    const eventStartDate = new Date(event.startDate);
    const eventEndDate = new Date(event.endDate);
    return {
      ...tacticInput,
      ...transformInputDateUTC([eventStartDate, addDays(eventEndDate, 1)]),
      eventId: event.eventId,
    };
  }

  return tacticInput;
};

