import { GridCellParams } from '@mui/x-data-grid';
import { t } from 'i18next';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStrategicImperativeListConfig from './StrategicImperativeList.config';
import { PAGINATION_PAGE_SIZE } from 'basics/constants/common.constant';
import {
  mayaCustomerInitiativeCreateRoute,
  mayaSiViewRoute,
  mayaStrategicImperativeCreateRoute,
  mayaStrategicImperativeDuplicateRoute,
  mayaStrategicImperativeEditRoute,
} from 'basics/constants/routes.constants';
import useGetAllStrategicImperativeQuery from 'basics/graphql/queries/getAllStrategicImperatives';
import { StrategicImperative } from 'generated/maya.types';
import { useQueryParams } from 'hooks/useQueryParams';

const useStrategicImperativeList = () => {
  const navigate = useNavigate();

  const [siTitleFilter, setSiTitleFilter] = useState<(string)>();
  const [strategicImperatives, setStrategicImperatives] = useState<StrategicImperative[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(0);
  const { year } = useQueryParams();

  const queryOptions = useMemo(
    () => ({
      limit: pageSize,
      offset: page * pageSize,
    }),
    [page, pageSize],
  );

  const queryFilters = useMemo(
    () => ({
      title: siTitleFilter,
      year: year ? parseInt(year, 10) : undefined,
    }),
    [siTitleFilter, year],
  );

  const { data, loading, count } = useGetAllStrategicImperativeQuery(queryFilters, queryOptions);

  useEffect(() => {
    setRowCountState((prevRowCountState) => (count !== undefined ? count : prevRowCountState));
  }, [count, setRowCountState]);

  useEffect(() => {
    if (!loading) {
      setStrategicImperatives(data);
    }
  }, [data, loading]);

  const newStrategicImperativeClick = useCallback(() => {
    navigate(mayaStrategicImperativeCreateRoute);
  }, [navigate]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const handleChangeSiTitle = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSiTitleFilter(event.target.value);
  };
  const debouncedHandleChangeSiTitle = useMemo(
    () => debounce(handleChangeSiTitle, 750),
    [],
  );

  const editSiCallback = async (ciId: string) => {
    navigate(`${mayaStrategicImperativeEditRoute}?id=${ciId}`);
  };

  const duplicateSiCallback = async (ciId: string) => {
    navigate(`${mayaStrategicImperativeDuplicateRoute}?cepSourceId=${ciId}`);
  };

  const addChildAction = async (ciId: string) => {
    navigate(`${mayaCustomerInitiativeCreateRoute}?parentId=${ciId}`);
  };

  const { strategicImperativeListColumnConfig, siTitleFieldConfig } = useStrategicImperativeListConfig(
    editSiCallback,
    duplicateSiCallback,
    addChildAction,
  );

  const handleClick = (params: GridCellParams) => {
    if (params.field !== 'action') {
      navigate(`${mayaSiViewRoute}?id=${params.row._id}`);
    }
  };

  const menuStrategicImperativeLabel = t('maya_main_menu_strategic_imperative_label');

  const newElementButton = {
    newElementLabel: t('maya_list_new_element_label', { elementTitle: menuStrategicImperativeLabel }),
    newElementClick: newStrategicImperativeClick,
  };

  return {
    debouncedHandleChangeSiTitle,
    handleClick,
    loading,
    menuStrategicImperativeLabel,
    newElementButton,
    onPageChange,
    onPageSizeChange,
    rowCountState,
    siTitleFieldConfig,
    strategicImperativeListColumnConfig,
    strategicImperatives,
  };
};

export default useStrategicImperativeList;
