import { gql, MutationResult, useMutation } from '@apollo/client';
import useFlags from 'launchdarkly-react-client-sdk/lib/useFlags';
import { useCallback } from 'react';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { cepFragment } from 'basics/graphql/fragments/cep.fragments';
import { tacticFragment } from 'basics/graphql/fragments/tactic.fragments';
import { MutationReturnType } from 'basics/types/maya.types';
import { CustomerEngagementPlanning, Tactic } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const duplicateCepMutationCall = gql`
mutation duplicateCustomerEngagementPlanning(
    $sourceCustomerEngagementPlanningId: String!,
    $duplicateCustomerEngagementPlanning: CustomerEngagementPlanningInput!,
    $sendToVeeva: Boolean,
    $deepDuplication: Boolean,
    ) {
        duplicateCustomerEngagementPlanning(
            sourceCustomerEngagementPlanningId: $sourceCustomerEngagementPlanningId,
            duplicateCustomerEngagementPlanningInput: $duplicateCustomerEngagementPlanning,
            sendToVeeva: $sendToVeeva,
            deepDuplication: $deepDuplication,
        ) {
    cep {
      ${cepFragment}
    }
    tactics {
      ${tacticFragment}
    }
  }
}
`;

export type DuplicateCustomerEngagementPlanning = {
  cep: CustomerEngagementPlanning;
  tactics: Tactic[];
};

type ResultType = {
  duplicateCustomerEngagementPlanning: DuplicateCustomerEngagementPlanning;
};

type UseDuplicateCepMutation = [
  (
    sourceCustomerEngagementPlanningId: string,
    duplicateCustomerEngagementPlanning: Partial<CustomerEngagementPlanning>,
    sendToVeeva?: boolean,
    deepDuplication?: boolean,
  )
  => Promise<MutationReturnType<{ cep: CustomerEngagementPlanning; tactics: Tactic[] }>>, MutationResult<ResultType>,
];

export const useDuplicateCepMutation = (): UseDuplicateCepMutation => {
  const [duplicateCep, state] = useMutation<ResultType>(duplicateCepMutationCall, {
    fetchPolicy: 'network-only',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  const flags = useFlags();

  const executeMutation = useCallback(async (cepSourceId, customerEngagementPlanning, sendToVeeva = true, deepDuplication = false) => {
    try {
      const { data } = await duplicateCep({
        variables: {
          sourceCustomerEngagementPlanningId: cepSourceId,
          duplicateCustomerEngagementPlanning: customerEngagementPlanning,
          sendToVeeva: flags.mayaEnableVeevaBiogenlinc1816 && sendToVeeva,
          deepDuplication: flags.mayaBdcfcsd1227EnableCepDeepDuplication && deepDuplication,
        },
      });

      return { data: data?.duplicateCustomerEngagementPlanning || null, error: null };
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, customerEngagementPlanning);
        Logger.logError({
          error,
          customContext,
          transactionName: 'duplicateCustomerEngagementPlanning.ts - useDuplicateCustomerEngagementPlanningMutation',
        });

        return { data: null, error: customContext.message as string };
      }

      return { data: null, error: null };
    }
  }, [duplicateCep, flags.mayaEnableVeevaBiogenlinc1816, flags.mayaBdcfcsd1227EnableCepDeepDuplication]);

  return [executeMutation, state];
};
