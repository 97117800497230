export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Upload: any;
};

export enum AssetTarget {
  aopImportCsv = 'aopImportCsv',
  targetListCsv = 'targetListCsv'
}

export enum AssetType {
  csv = 'csv'
}

export type AssetsMaya = {
  __typename?: 'AssetsMaya';
  _id: Scalars['ID'];
  assetTarget?: Maybe<AssetTarget>;
  assetType?: Maybe<AssetType>;
  bucket: Scalars['String'];
  eTag: Scalars['String'];
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  size: Scalars['Float'];
  url: Scalars['String'];
};

export type AssetsMayaInput = {
  _id: Scalars['ID'];
  assetTarget?: InputMaybe<AssetTarget>;
  assetType?: InputMaybe<AssetType>;
  bucket: Scalars['String'];
  eTag: Scalars['String'];
  key: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  size: Scalars['Float'];
  url: Scalars['String'];
};

export type BulkUpdateTacticInput = {
  _id: Scalars['ID'];
  actionOrder?: InputMaybe<Scalars['Float']>;
  approvedDocumentId?: InputMaybe<Scalars['String']>;
  assetId?: InputMaybe<Scalars['String']>;
  assetName?: InputMaybe<Scalars['String']>;
  callToAction?: InputMaybe<Scalars['String']>;
  cep?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<Scalars['String']>;
  clickUrl?: InputMaybe<Scalars['String']>;
  dateEnd?: InputMaybe<Scalars['DateTime']>;
  dateStart?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  destinationUrl?: InputMaybe<Scalars['String']>;
  dropMaterial?: InputMaybe<DropMaterialInput>;
  engagementMetric?: InputMaybe<Scalars['String']>;
  engagementValue?: InputMaybe<Scalars['Float']>;
  eventId?: InputMaybe<Scalars['String']>;
  executingTeam?: InputMaybe<Scalars['String']>;
  includeConsent?: InputMaybe<Scalars['Boolean']>;
  isKeyPerformanceIndicator?: InputMaybe<Scalars['Boolean']>;
  keyMessage?: InputMaybe<KeyMessageInput>;
  mandatory?: InputMaybe<Scalars['Boolean']>;
  parentEvent?: InputMaybe<Scalars['Boolean']>;
  parentTactic?: InputMaybe<Scalars['String']>;
  previousTactic?: InputMaybe<Scalars['String']>;
  productionOwner?: InputMaybe<Scalars['String']>;
  reachMetric?: InputMaybe<Scalars['String']>;
  reachValue?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['String']>;
  tacticId?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CepFilter = {
  _id?: InputMaybe<Scalars['String']>;
  cepFunction?: InputMaybe<Array<Scalars['String']>>;
  cepStatus?: InputMaybe<Array<Scalars['String']>>;
  channel?: InputMaybe<Array<Scalars['String']>>;
  countries?: InputMaybe<Array<Scalars['String']>>;
  customerInitiative?: InputMaybe<Array<Scalars['String']>>;
  customerType?: InputMaybe<Array<Scalars['String']>>;
  dateEnd?: InputMaybe<Scalars['DateTime']>;
  dateStart?: InputMaybe<Scalars['DateTime']>;
  getPopularity?: InputMaybe<Scalars['Boolean']>;
  isTemplate?: InputMaybe<Scalars['Boolean']>;
  product?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  specialty?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<Scalars['String']>>;
  therapeuticArea?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export enum Channel {
  approvedEmail = 'approvedEmail',
  broadcastEmail = 'broadcastEmail',
  f2fEvent = 'f2fEvent',
  f2fMeeting = 'f2fMeeting',
  group = 'group',
  hybridEvent = 'hybridEvent',
  oneToOne = 'oneToOne',
  telephone = 'telephone',
  virtualEvent = 'virtualEvent',
  virtualMeeting = 'virtualMeeting',
  webLogIn = 'webLogIn',
  webNoLogIn = 'webNoLogIn'
}

export type ChannelAffinity = {
  __typename?: 'ChannelAffinity';
  allDigital?: Maybe<Array<Scalars['String']>>;
  allF2F?: Maybe<Array<Scalars['String']>>;
  approvedEmails?: Maybe<Array<Scalars['String']>>;
  broadcastEmails?: Maybe<Array<Scalars['String']>>;
  f2f?: Maybe<Array<Scalars['String']>>;
  f2fEvent?: Maybe<Array<Scalars['String']>>;
  phone?: Maybe<Array<Scalars['String']>>;
  virtual?: Maybe<Array<Scalars['String']>>;
  virtualEvent?: Maybe<Array<Scalars['String']>>;
  webSessions?: Maybe<Array<Scalars['String']>>;
};

export type ChannelAffinityFilterInput = {
  allDigital?: InputMaybe<Array<SizingFilter>>;
  allF2F?: InputMaybe<Array<SizingFilter>>;
  approvedEmails?: InputMaybe<Array<SizingFilter>>;
  broadcastEmails?: InputMaybe<Array<SizingFilter>>;
  f2f?: InputMaybe<Array<SizingFilter>>;
  f2fEvent?: InputMaybe<Array<SizingFilter>>;
  phone?: InputMaybe<Array<SizingFilter>>;
  virtual?: InputMaybe<Array<SizingFilter>>;
  virtualEvent?: InputMaybe<Array<SizingFilter>>;
  webSessions?: InputMaybe<Array<SizingFilter>>;
};

export type CiFilter = {
  _id?: InputMaybe<Scalars['String']>;
  cepFunction?: InputMaybe<Array<Scalars['String']>>;
  cepStatus?: InputMaybe<Array<Scalars['String']>>;
  countries?: InputMaybe<Array<Scalars['String']>>;
  dateEnd?: InputMaybe<Scalars['DateTime']>;
  dateRange?: InputMaybe<Array<Scalars['DateTime']>>;
  dateStart?: InputMaybe<Scalars['DateTime']>;
  isTemplate?: InputMaybe<Scalars['Boolean']>;
  lastVeevaSync?: InputMaybe<Scalars['Boolean']>;
  product?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<Scalars['String']>>;
  therapeuticArea?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Float']>;
  years?: InputMaybe<Array<Scalars['Float']>>;
};

export type CiGoals = {
  __typename?: 'CiGoals';
  engagement: Scalars['Float'];
  reach: Scalars['Float'];
};

export type CountType = {
  __typename?: 'CountType';
  count: Scalars['Float'];
};

export enum CtaType {
  click = 'click',
  open = 'open'
}

export type CustomerEngagementPlanning = {
  __typename?: 'CustomerEngagementPlanning';
  _id: Scalars['ID'];
  biogenFunction: Scalars['String'];
  cepId: Scalars['Float'];
  createdBy: Scalars['String'];
  customerInitiative: CustomerInitiative;
  customerType: Array<Scalars['String']>;
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  formattedCepId: Scalars['String'];
  globalCepSyncStatus: GlobalStatusType;
  keyMessages?: Maybe<Scalars['String']>;
  kpi: Scalars['String'];
  lastModifiedBy: Scalars['String'];
  lastVeevaSync?: Maybe<Scalars['DateTime']>;
  lastVeevaSyncError?: Maybe<Scalars['String']>;
  npsGoal?: Maybe<Scalars['Float']>;
  popularity?: Maybe<Scalars['Float']>;
  product: Scalars['String'];
  published?: Maybe<Scalars['DateTime']>;
  reachGoal?: Maybe<Scalars['Float']>;
  sourceId?: Maybe<Scalars['String']>;
  specialty: Array<Scalars['String']>;
  status: Scalars['String'];
  targetEngagementGoal?: Maybe<Scalars['Float']>;
  targetFilter?: Maybe<TargetFilter>;
  targetListDescription?: Maybe<Scalars['String']>;
  therapeuticArea: Scalars['String'];
  title: Scalars['String'];
  updated: Scalars['DateTime'];
  veevaSyncStatus?: Maybe<Scalars['String']>;
};

export type CustomerEngagementPlanningInput = {
  biogenFunction: Scalars['String'];
  cepId?: InputMaybe<Scalars['Float']>;
  customerInitiative: Scalars['String'];
  customerType: Array<Scalars['String']>;
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  description: Scalars['String'];
  keyMessages?: InputMaybe<Scalars['String']>;
  kpi: Scalars['String'];
  npsGoal?: InputMaybe<Scalars['Float']>;
  product: Scalars['String'];
  reachGoal?: InputMaybe<Scalars['Float']>;
  sourceId?: InputMaybe<Scalars['String']>;
  specialty: Array<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  targetEngagementGoal?: InputMaybe<Scalars['Float']>;
  targetFilter?: InputMaybe<TargetFilterInput>;
  targetListDescription?: InputMaybe<Scalars['String']>;
  therapeuticArea: Scalars['String'];
  title: Scalars['String'];
};

export type CustomerInitiative = {
  __typename?: 'CustomerInitiative';
  _id: Scalars['ID'];
  ciId: Scalars['Float'];
  country: Scalars['String'];
  createdBy: Scalars['String'];
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  description: Scalars['String'];
  formattedCiId: Scalars['String'];
  functionalOwner: Scalars['String'];
  functionsInvolved: Array<Scalars['String']>;
  goals: CiGoals;
  isTemplate: Scalars['Boolean'];
  lastModifiedBy: Scalars['String'];
  lastVeevaSync?: Maybe<Scalars['DateTime']>;
  lastVeevaSyncError?: Maybe<Scalars['String']>;
  problemStatement?: Maybe<Scalars['String']>;
  product: Scalars['String'];
  solutionCoreStory: Scalars['String'];
  status: Scalars['String'];
  strategicImperative: StrategicImperative;
  therapeuticArea: Scalars['String'];
  title: Scalars['String'];
  updated: Scalars['DateTime'];
  veevaSyncStatus?: Maybe<Scalars['String']>;
  year: Scalars['Float'];
};

export type CustomerInitiativeInput = {
  ciId?: InputMaybe<Scalars['Float']>;
  country: Scalars['String'];
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  description: Scalars['String'];
  functionalOwner: Scalars['String'];
  functionsInvolved: Array<Scalars['String']>;
  isTemplate?: InputMaybe<Scalars['Boolean']>;
  problemStatement?: InputMaybe<Scalars['String']>;
  product: Scalars['String'];
  solutionCoreStory: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  strategicImperative: Scalars['String'];
  therapeuticArea: Scalars['String'];
  title: Scalars['String'];
  year: Scalars['Float'];
};

export type DashboardFilter = {
  cepFunction?: InputMaybe<Array<Scalars['String']>>;
  cepStatus?: InputMaybe<Array<Scalars['String']>>;
  countries?: InputMaybe<Array<Scalars['String']>>;
  dateEnd?: InputMaybe<Scalars['DateTime']>;
  dateStart?: InputMaybe<Scalars['DateTime']>;
  product?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<Scalars['String']>>;
  tacticStatus?: InputMaybe<Array<Scalars['String']>>;
  therapeuticArea?: InputMaybe<Scalars['String']>;
};

export type DashboardFilterInput = {
  cepFunction?: InputMaybe<Array<Scalars['String']>>;
  cepStatus?: InputMaybe<Array<Scalars['String']>>;
  countries?: InputMaybe<Array<Scalars['String']>>;
  dateEnd?: InputMaybe<Scalars['DateTime']>;
  dateStart?: InputMaybe<Scalars['DateTime']>;
  product?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<Scalars['String']>>;
  tacticStatus?: InputMaybe<Array<Scalars['String']>>;
  therapeuticArea?: InputMaybe<Scalars['String']>;
};

export type DashboardFilterObjectType = {
  __typename?: 'DashboardFilterObjectType';
  cepFunction?: Maybe<Array<Scalars['String']>>;
  cepStatus?: Maybe<Array<Scalars['String']>>;
  countries?: Maybe<Array<Scalars['String']>>;
  dateEnd?: Maybe<Scalars['DateTime']>;
  dateStart?: Maybe<Scalars['DateTime']>;
  product?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Array<Scalars['String']>>;
  tacticStatus?: Maybe<Array<Scalars['String']>>;
  therapeuticArea?: Maybe<Scalars['String']>;
};

export type DropMaterialInput = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type DropMaterialType = {
  __typename?: 'DropMaterialType';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type DropMaterialsFilter = {
  Country?: InputMaybe<Scalars['String']>;
  TherapeuticArea?: InputMaybe<Scalars['String']>;
};

export type EngagementReturnType = {
  __typename?: 'EngagementReturnType';
  engagement: Scalars['Float'];
  percentage: Scalars['Float'];
};

export type FileDataInput = {
  assetTarget?: InputMaybe<AssetTarget>;
  assetType?: InputMaybe<AssetType>;
  name?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
};

export type GetCepType = {
  __typename?: 'GetCepType';
  count: Scalars['Float'];
  customerEngagementPlannings: Array<CustomerEngagementPlanning>;
};

export type GetCiYearsType = {
  __typename?: 'GetCiYearsType';
  years: Array<Scalars['Float']>;
};

export type GetCustomerInitiativeType = {
  __typename?: 'GetCustomerInitiativeType';
  count: Scalars['Float'];
  customerInitiatives: Array<CustomerInitiative>;
};

export type GetStrategicImperativeType = {
  __typename?: 'GetStrategicImperativeType';
  count: Scalars['Float'];
  strategicImperatives: Array<StrategicImperative>;
};

export type GetStrategicImperativesYearsType = {
  __typename?: 'GetStrategicImperativesYearsType';
  years: Array<Scalars['Float']>;
};

export type GetTacticsType = {
  __typename?: 'GetTacticsType';
  count: Scalars['Float'];
  tactics: Array<Tactic>;
};

export type GetTargetsType = {
  __typename?: 'GetTargetsType';
  count: Scalars['Float'];
  targets: Array<Target>;
};

export type GlobalStatusType = {
  __typename?: 'GlobalStatusType';
  errors: Array<Scalars['String']>;
  syncStatus: Scalars['String'];
};

export enum InitiativeCountry {
  AE = 'AE',
  AM = 'AM',
  APAC = 'APAC',
  AR = 'AR',
  AT = 'AT',
  AU = 'AU',
  BE = 'BE',
  BH = 'BH',
  BR = 'BR',
  CA = 'CA',
  CH = 'CH',
  CL = 'CL',
  CN = 'CN',
  CO = 'CO',
  CZ = 'CZ',
  DE = 'DE',
  DK = 'DK',
  ECP = 'ECP',
  EE = 'EE',
  ES = 'ES',
  FI = 'FI',
  FR = 'FR',
  GB = 'GB',
  HK = 'HK',
  HR = 'HR',
  HU = 'HU',
  IE = 'IE',
  IT = 'IT',
  JP = 'JP',
  LATAM = 'LATAM',
  LT = 'LT',
  LV = 'LV',
  MX = 'MX',
  NL = 'NL',
  NO = 'NO',
  NZ = 'NZ',
  OM = 'OM',
  PL = 'PL',
  PT = 'PT',
  QA = 'QA',
  SA = 'SA',
  SE = 'SE',
  SI = 'SI',
  SK = 'SK',
  TR = 'TR',
  TW = 'TW',
  UY = 'UY'
}

export type KeyMessage = {
  __typename?: 'KeyMessage';
  BusinessUnit?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  Commercial?: Maybe<Scalars['Boolean']>;
  Country?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DetailGroupName?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Function?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  KeyName?: Maybe<Scalars['String']>;
  MarketAccess?: Maybe<Scalars['Boolean']>;
  Medical?: Maybe<Scalars['Boolean']>;
  ProductName?: Maybe<Scalars['String']>;
  Sales?: Maybe<Scalars['Boolean']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  VeevaExternalID?: Maybe<Scalars['String']>;
};

export type KeyMessageInput = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type KeyMessageType = {
  __typename?: 'KeyMessageType';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type KeyMessagesFilter = {
  ActiveStatusFlag?: InputMaybe<Scalars['String']>;
  BusinessUnit?: InputMaybe<Scalars['String']>;
  CommercialFlag?: InputMaybe<Scalars['String']>;
  Country?: InputMaybe<Scalars['String']>;
  MarketAccessFlag?: InputMaybe<Scalars['String']>;
  MedicalFlag?: InputMaybe<Scalars['String']>;
  Product?: InputMaybe<Scalars['String']>;
  Type?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  bulkCreateTargetList: Array<Target>;
  bulkCustomerEngagementPlanning: Array<CustomerEngagementPlanning>;
  bulkCustomerInitiative: Array<CustomerInitiative>;
  bulkStrategicImperative: Array<StrategicImperative>;
  bulkTactic: Array<Tactic>;
  bulkUpdateTactic: Array<Tactic>;
  createCustomerEngagementPlanning: CustomerEngagementPlanning;
  createCustomerInitiative: CustomerInitiative;
  createOrUpdateUserData: UserData;
  createStrategicImperative: StrategicImperative;
  createTactic: Tactic;
  createTargetListFromCSV: Array<Target>;
  createVeevaTargets: Array<Target>;
  duplicateCustomerEngagementPlanning: ReturnDuplicateType;
  importAopFromCSV: Scalars['Float'];
  removeCustomerEngagementPlanning: CustomerEngagementPlanning;
  removeCustomerInitiative: CustomerInitiative;
  removeStrategicImperative: StrategicImperative;
  removeTargetsByCepId: RemovedTargetsType;
  setCepStatusToApproved: Scalars['Boolean'];
  setCepStatusToDeleted: Scalars['Boolean'];
  setCepStatusToFeedback: Scalars['Boolean'];
  setCepStatusToRestored: Scalars['Boolean'];
  setCiStatusToActive: Scalars['Boolean'];
  setCiStatusToDeleted: Scalars['Boolean'];
  setTacticStatusToDeleted: Scalars['Boolean'];
  syncCepToVeeva: Scalars['Boolean'];
  syncCiToVeeva: Scalars['Boolean'];
  syncTargetsToVeeva: SyncVeevaTargets;
  updateCustomerEngagementPlanning: CustomerEngagementPlanning;
  updateCustomerInitiative: CustomerInitiative;
  updateStrategicImperative: StrategicImperative;
  updateTactic: Tactic;
};


export type MutationBulkCreateTargetListArgs = {
  cepId: Scalars['String'];
  targets: Array<TargetInput>;
};


export type MutationBulkCustomerEngagementPlanningArgs = {
  bulkCustomerEngagementPlanningInput: Array<CustomerEngagementPlanningInput>;
  sendToVeeva?: InputMaybe<Scalars['Boolean']>;
};


export type MutationBulkCustomerInitiativeArgs = {
  bulkCustomerInitiativeInput: Array<CustomerInitiativeInput>;
  sendToVeeva?: InputMaybe<Scalars['Boolean']>;
};


export type MutationBulkStrategicImperativeArgs = {
  bulkStrategicImperativeInput: Array<StrategicImperativeInput>;
};


export type MutationBulkTacticArgs = {
  bulkTacticInput: Array<TacticInput>;
  cepId: Scalars['String'];
  sendToVeeva?: InputMaybe<Scalars['Boolean']>;
};


export type MutationBulkUpdateTacticArgs = {
  bulkTacticInput: Array<BulkUpdateTacticInput>;
  cepId: Scalars['String'];
  sendToVeeva?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateCustomerEngagementPlanningArgs = {
  createCustomerEngagementPlanningInput: CustomerEngagementPlanningInput;
  sendToVeeva?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateCustomerInitiativeArgs = {
  createCustomerInitiativeInput: CustomerInitiativeInput;
  sendToVeeva?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateOrUpdateUserDataArgs = {
  userData: UserDataInput;
};


export type MutationCreateStrategicImperativeArgs = {
  createStrategicImperativeInput: StrategicImperativeInput;
};


export type MutationCreateTacticArgs = {
  cepId: Scalars['String'];
  createTacticInput: TacticInput;
  sendToVeeva?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateTargetListFromCsvArgs = {
  cepId: Scalars['String'];
  file: Scalars['Upload'];
  fileDataInput: FileDataInput;
  formattedCepId: Scalars['String'];
  sendToVeeva?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateVeevaTargetsArgs = {
  cepId: Scalars['String'];
  filters: TargetFilterInput;
};


export type MutationDuplicateCustomerEngagementPlanningArgs = {
  deepDuplication?: InputMaybe<Scalars['Boolean']>;
  duplicateCustomerEngagementPlanningInput: CustomerEngagementPlanningInput;
  sendToVeeva?: InputMaybe<Scalars['Boolean']>;
  sourceCustomerEngagementPlanningId: Scalars['String'];
};


export type MutationImportAopFromCsvArgs = {
  file: Scalars['Upload'];
  fileDataInput: FileDataInput;
  sendToVeeva?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRemoveCustomerEngagementPlanningArgs = {
  id: Scalars['String'];
};


export type MutationRemoveCustomerInitiativeArgs = {
  id: Scalars['String'];
};


export type MutationRemoveStrategicImperativeArgs = {
  id: Scalars['String'];
};


export type MutationRemoveTargetsByCepIdArgs = {
  cepId: Scalars['String'];
  sendToVeeva?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSetCepStatusToApprovedArgs = {
  id: Scalars['String'];
  sendToVeeva?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSetCepStatusToDeletedArgs = {
  id: Scalars['String'];
  sendToVeeva?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSetCepStatusToFeedbackArgs = {
  id: Scalars['String'];
  sendToVeeva?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSetCepStatusToRestoredArgs = {
  id: Scalars['String'];
  sendToVeeva?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSetCiStatusToActiveArgs = {
  id: Scalars['String'];
  sendToVeeva?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSetCiStatusToDeletedArgs = {
  id: Scalars['String'];
  sendToVeeva?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSetTacticStatusToDeletedArgs = {
  id: Scalars['String'];
  sendToVeeva?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSyncCepToVeevaArgs = {
  id: Scalars['String'];
};


export type MutationSyncCiToVeevaArgs = {
  id: Scalars['String'];
};


export type MutationSyncTargetsToVeevaArgs = {
  cepId: Scalars['String'];
};


export type MutationUpdateCustomerEngagementPlanningArgs = {
  id: Scalars['String'];
  sendToVeeva?: InputMaybe<Scalars['Boolean']>;
  updateCustomerEngagementPlanningInput: UpdateCustomerEngagementPlanningInput;
};


export type MutationUpdateCustomerInitiativeArgs = {
  id: Scalars['String'];
  sendToVeeva?: InputMaybe<Scalars['Boolean']>;
  updateCustomerInitiativeInput: UpdateCustomerInitiativeInput;
};


export type MutationUpdateStrategicImperativeArgs = {
  id: Scalars['String'];
  updateStrategicImperativeInput: UpdateStrategicImperativeInput;
};


export type MutationUpdateTacticArgs = {
  cepId: Scalars['String'];
  id: Scalars['String'];
  sendToVeeva?: InputMaybe<Scalars['Boolean']>;
  updateTacticInput: UpdateTacticInput;
};

export type Products = {
  __typename?: 'Products';
  commercialFunctionFlag: Scalars['Boolean'];
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  marketAccessFunctionFlag?: Maybe<Scalars['Boolean']>;
  medicalFunctionFlag?: Maybe<Scalars['Boolean']>;
  parentProductExternalID?: Maybe<Scalars['String']>;
  parentProductName?: Maybe<Scalars['String']>;
  productExternalID?: Maybe<Scalars['String']>;
  productID?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  therapeuticArea?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  countCustomerEngagementPlannings: CountType;
  countTactics: CountType;
  countTargets: CountType;
  exportData: StreamableBuffer;
  exportTargetList: Scalars['String'];
  getAllVeevaVaultDocument: Array<VeevaVaultDocument>;
  getCepEngagement: Scalars['Float'];
  getCepReach: Scalars['Float'];
  getCustomerEngagementPlanningById: CustomerEngagementPlanning;
  getCustomerEngagementPlannings: GetCepType;
  getCustomerInitiativeById: CustomerInitiative;
  getCustomerInitiatives: GetCustomerInitiativeType;
  getDashboardCI: GetCustomerInitiativeType;
  getDropMaterials: Array<Products>;
  getEngagement: EngagementReturnType;
  getFacetCiYears: GetCiYearsType;
  getFacetStrategicImperativesYears: GetStrategicImperativesYearsType;
  getKeyMessages: Array<KeyMessage>;
  getNextTactics: GetTacticsType;
  getStrategicImperativeById: StrategicImperative;
  getStrategicImperatives: GetStrategicImperativeType;
  getTacticById: Tactic;
  getTactics: GetTacticsType;
  getTacticsByCepId: GetTacticsType;
  getTargetList: GetTargetsType;
  getTargetListByCepId: GetTargetsType;
  getTargetsEngagement: Scalars['Float'];
  getTargetsFacets: TargetsFacets;
  getTargetsReach: Scalars['Float'];
  getUserData?: Maybe<UserData>;
  uploadFiles: Array<AssetsMaya>;
};


export type QueryCountCustomerEngagementPlanningsArgs = {
  filters: DashboardFilter;
};


export type QueryCountTacticsArgs = {
  filters: DashboardFilter;
};


export type QueryCountTargetsArgs = {
  filters: DashboardFilter;
};


export type QueryExportDataArgs = {
  countries: Array<InitiativeCountry>;
  from?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
};


export type QueryExportTargetListArgs = {
  cepId: Scalars['String'];
};


export type QueryGetAllVeevaVaultDocumentArgs = {
  filters?: InputMaybe<VeevaVaultDocumentFilter>;
  limit?: InputMaybe<VeevaVaultDocumentLimit>;
};


export type QueryGetCepEngagementArgs = {
  cepId: Scalars['String'];
};


export type QueryGetCepReachArgs = {
  cepId: Scalars['String'];
};


export type QueryGetCustomerEngagementPlanningByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetCustomerEngagementPlanningsArgs = {
  filters?: InputMaybe<CepFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCustomerInitiativeByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetCustomerInitiativesArgs = {
  filters?: InputMaybe<CiFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetDashboardCiArgs = {
  filters: DashboardFilter;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetDropMaterialsArgs = {
  filters?: InputMaybe<DropMaterialsFilter>;
};


export type QueryGetEngagementArgs = {
  cepId: Scalars['String'];
  channel: Scalars['String'];
};


export type QueryGetKeyMessagesArgs = {
  filters?: InputMaybe<KeyMessagesFilter>;
};


export type QueryGetNextTacticsArgs = {
  id: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetStrategicImperativeByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetStrategicImperativesArgs = {
  filters?: InputMaybe<StrategicImperativeFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetTacticByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetTacticsArgs = {
  filters?: InputMaybe<TacticFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetTacticsByCepIdArgs = {
  cepId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetTargetListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetTargetListByCepIdArgs = {
  cepId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetTargetsEngagementArgs = {
  filters: DashboardFilter;
};


export type QueryGetTargetsFacetsArgs = {
  filters: TargetFilterInput;
};


export type QueryGetTargetsReachArgs = {
  filters: DashboardFilter;
};


export type QueryUploadFilesArgs = {
  assetsArePublic: Scalars['Boolean'];
  files: Array<Scalars['Upload']>;
  filesDataInput: Array<FileDataInput>;
  prefix: Scalars['String'];
};

export type RemovedTargetsType = {
  __typename?: 'RemovedTargetsType';
  deletedCount: Scalars['Float'];
};

export enum SizingFilter {
  H = 'H',
  L = 'L',
  M = 'M',
  NONE = 'NONE'
}

export type StrategicImperative = {
  __typename?: 'StrategicImperative';
  _id: Scalars['ID'];
  createdBy: Scalars['String'];
  description: Scalars['String'];
  lastModifiedBy: Scalars['String'];
  siId: Scalars['Float'];
  therapeuticArea: Scalars['String'];
  title: Scalars['String'];
  updated: Scalars['DateTime'];
  year: Scalars['Float'];
};

export type StrategicImperativeFilter = {
  _id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Float']>;
};

export type StrategicImperativeInput = {
  description: Scalars['String'];
  therapeuticArea: Scalars['String'];
  title: Scalars['String'];
  year: Scalars['Float'];
};

export type StreamableBuffer = {
  __typename?: 'StreamableBuffer';
  buffer?: Maybe<Scalars['String']>;
};

export type SyncVeevaTargets = {
  __typename?: 'SyncVeevaTargets';
  errors: Array<Scalars['String']>;
  syncVeeva: Scalars['Boolean'];
};

export type Tactic = {
  __typename?: 'Tactic';
  _id: Scalars['ID'];
  actionOrder?: Maybe<Scalars['Float']>;
  approvedDocumentId?: Maybe<Scalars['String']>;
  assetId?: Maybe<Scalars['String']>;
  assetName?: Maybe<Scalars['String']>;
  callToAction?: Maybe<CtaType>;
  cep: CustomerEngagementPlanning;
  channel: Channel;
  clickUrl?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  dateEnd?: Maybe<Scalars['DateTime']>;
  dateStart?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  destinationUrl?: Maybe<Scalars['String']>;
  dropMaterial?: Maybe<DropMaterialType>;
  engagementMetric?: Maybe<Scalars['String']>;
  engagementValue?: Maybe<Scalars['Float']>;
  eventId?: Maybe<Scalars['String']>;
  executingTeam: Scalars['String'];
  formattedTacticId: Scalars['String'];
  includeConsent?: Maybe<Scalars['Boolean']>;
  isKeyPerformanceIndicator?: Maybe<Scalars['Boolean']>;
  keyMessage?: Maybe<KeyMessageType>;
  lastModifiedBy: Scalars['String'];
  lastVeevaSync?: Maybe<Scalars['DateTime']>;
  lastVeevaSyncError?: Maybe<Scalars['String']>;
  mandatory?: Maybe<Scalars['Boolean']>;
  parentEvent?: Maybe<Scalars['Boolean']>;
  parentTactic?: Maybe<Tactic>;
  previousTactic?: Maybe<Tactic>;
  productionOwner?: Maybe<Scalars['String']>;
  reachMetric?: Maybe<Scalars['String']>;
  reachValue?: Maybe<Scalars['Float']>;
  status: Scalars['String'];
  tacticId: Scalars['Float'];
  title: Scalars['String'];
  updated: Scalars['DateTime'];
  veevaSyncStatus?: Maybe<Scalars['String']>;
};

export type TacticFilters = {
  cep?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<Array<Scalars['String']>>;
  previousTactic?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<Scalars['String']>>;
};

export type TacticInput = {
  actionOrder?: InputMaybe<Scalars['Float']>;
  approvedDocumentId?: InputMaybe<Scalars['String']>;
  assetId?: InputMaybe<Scalars['String']>;
  assetName?: InputMaybe<Scalars['String']>;
  callToAction?: InputMaybe<Scalars['String']>;
  cep: Scalars['String'];
  channel: Scalars['String'];
  clickUrl?: InputMaybe<Scalars['String']>;
  dateEnd?: InputMaybe<Scalars['DateTime']>;
  dateStart?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  destinationUrl?: InputMaybe<Scalars['String']>;
  dropMaterial?: InputMaybe<DropMaterialInput>;
  engagementMetric?: InputMaybe<Scalars['String']>;
  engagementValue?: InputMaybe<Scalars['Float']>;
  eventId?: InputMaybe<Scalars['String']>;
  executingTeam: Scalars['String'];
  includeConsent?: InputMaybe<Scalars['Boolean']>;
  isKeyPerformanceIndicator?: InputMaybe<Scalars['Boolean']>;
  keyMessage?: InputMaybe<KeyMessageInput>;
  mandatory?: InputMaybe<Scalars['Boolean']>;
  parentEvent?: InputMaybe<Scalars['Boolean']>;
  parentTactic?: InputMaybe<Scalars['String']>;
  previousTactic?: InputMaybe<Scalars['String']>;
  productionOwner?: InputMaybe<Scalars['String']>;
  reachMetric?: InputMaybe<Scalars['String']>;
  reachValue?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['String']>;
  tacticId?: InputMaybe<Scalars['Float']>;
  title: Scalars['String'];
};

export type Target = {
  __typename?: 'Target';
  _id: Scalars['ID'];
  cep: CustomerEngagementPlanning;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastVeevaSync?: Maybe<Scalars['DateTime']>;
  lastVeevaSyncError?: Maybe<Scalars['String']>;
  mdmId: Scalars['String'];
  targetId: Scalars['Float'];
  veevaSyncStatus?: Maybe<Scalars['String']>;
};

export type TargetFilter = {
  __typename?: 'TargetFilter';
  biosSegments?: Maybe<Array<Scalars['String']>>;
  businessUnits?: Maybe<Array<Scalars['String']>>;
  channelAffinity?: Maybe<ChannelAffinity>;
  consent?: Maybe<Array<Scalars['String']>>;
  countries?: Maybe<Array<Scalars['String']>>;
  cyclePlan?: Maybe<Scalars['Boolean']>;
  hcoNames?: Maybe<Array<Scalars['String']>>;
  imsSegments?: Maybe<Array<Scalars['String']>>;
  smaSegments?: Maybe<Array<Scalars['String']>>;
  specialties?: Maybe<Array<Scalars['String']>>;
  types?: Maybe<Array<Scalars['String']>>;
};

export type TargetFilterInput = {
  biosSegments?: InputMaybe<Array<Scalars['String']>>;
  businessUnits?: InputMaybe<Array<Scalars['String']>>;
  channelAffinity?: InputMaybe<ChannelAffinityFilterInput>;
  consent?: InputMaybe<Array<Scalars['String']>>;
  countries?: InputMaybe<Array<Scalars['String']>>;
  cyclePlan?: InputMaybe<Scalars['Boolean']>;
  hcoNames?: InputMaybe<Array<Scalars['String']>>;
  imsSegments?: InputMaybe<Array<Scalars['String']>>;
  smaSegments?: InputMaybe<Array<Scalars['String']>>;
  specialties?: InputMaybe<Array<Scalars['String']>>;
  types?: InputMaybe<Array<Scalars['String']>>;
};

export type TargetInput = {
  cep?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  mdmId: Scalars['String'];
};

export type TargetsFacets = {
  __typename?: 'TargetsFacets';
  biosSegments: Array<Scalars['String']>;
  businessUnits: Array<Scalars['String']>;
  consent: Array<Scalars['String']>;
  country: Array<Scalars['String']>;
  hcoNames: Array<Scalars['String']>;
  imsSegments: Array<Scalars['String']>;
  smaSegments: Array<Scalars['String']>;
  specialties: Array<Scalars['String']>;
  types: Array<Scalars['String']>;
};

export type UpdateCustomerEngagementPlanningInput = {
  biogenFunction?: InputMaybe<Scalars['String']>;
  cepId?: InputMaybe<Scalars['Float']>;
  customerInitiative?: InputMaybe<Scalars['String']>;
  customerType?: InputMaybe<Array<Scalars['String']>>;
  dateEnd?: InputMaybe<Scalars['DateTime']>;
  dateStart?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  keyMessages?: InputMaybe<Scalars['String']>;
  kpi?: InputMaybe<Scalars['String']>;
  npsGoal?: InputMaybe<Scalars['Float']>;
  product?: InputMaybe<Scalars['String']>;
  reachGoal?: InputMaybe<Scalars['Float']>;
  sourceId?: InputMaybe<Scalars['String']>;
  specialty?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Scalars['String']>;
  targetEngagementGoal?: InputMaybe<Scalars['Float']>;
  targetFilter?: InputMaybe<TargetFilterInput>;
  targetListDescription?: InputMaybe<Scalars['String']>;
  therapeuticArea?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerInitiativeInput = {
  ciId?: InputMaybe<Scalars['Float']>;
  country?: InputMaybe<Scalars['String']>;
  dateEnd?: InputMaybe<Scalars['DateTime']>;
  dateStart?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  functionalOwner?: InputMaybe<Scalars['String']>;
  functionsInvolved?: InputMaybe<Array<Scalars['String']>>;
  isTemplate?: InputMaybe<Scalars['Boolean']>;
  problemStatement?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Scalars['String']>;
  solutionCoreStory?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  strategicImperative?: InputMaybe<Scalars['String']>;
  therapeuticArea?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Float']>;
};

export type UpdateStrategicImperativeInput = {
  description?: InputMaybe<Scalars['String']>;
  therapeuticArea?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Float']>;
};

export type UpdateTacticInput = {
  actionOrder?: InputMaybe<Scalars['Float']>;
  approvedDocumentId?: InputMaybe<Scalars['String']>;
  assetId?: InputMaybe<Scalars['String']>;
  assetName?: InputMaybe<Scalars['String']>;
  callToAction?: InputMaybe<Scalars['String']>;
  cep?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<Scalars['String']>;
  clickUrl?: InputMaybe<Scalars['String']>;
  dateEnd?: InputMaybe<Scalars['DateTime']>;
  dateStart?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  destinationUrl?: InputMaybe<Scalars['String']>;
  dropMaterial?: InputMaybe<DropMaterialInput>;
  engagementMetric?: InputMaybe<Scalars['String']>;
  engagementValue?: InputMaybe<Scalars['Float']>;
  eventId?: InputMaybe<Scalars['String']>;
  executingTeam?: InputMaybe<Scalars['String']>;
  includeConsent?: InputMaybe<Scalars['Boolean']>;
  isKeyPerformanceIndicator?: InputMaybe<Scalars['Boolean']>;
  keyMessage?: InputMaybe<KeyMessageInput>;
  mandatory?: InputMaybe<Scalars['Boolean']>;
  parentEvent?: InputMaybe<Scalars['Boolean']>;
  parentTactic?: InputMaybe<Scalars['String']>;
  previousTactic?: InputMaybe<Scalars['String']>;
  productionOwner?: InputMaybe<Scalars['String']>;
  reachMetric?: InputMaybe<Scalars['String']>;
  reachValue?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['String']>;
  tacticId?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UserData = {
  __typename?: 'UserData';
  _id: Scalars['ID'];
  country?: Maybe<Scalars['String']>;
  dashboardFilters?: Maybe<DashboardFilterObjectType>;
  userId: Scalars['String'];
};

export type UserDataInput = {
  country?: InputMaybe<Scalars['String']>;
  dashboardFilters?: InputMaybe<DashboardFilterInput>;
};

export type VeevaVaultDocument = {
  __typename?: 'VeevaVaultDocument';
  documentNumber: Scalars['String'];
  id: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type VeevaVaultDocumentFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type VeevaVaultDocumentLimit = {
  limit?: InputMaybe<Scalars['Float']>;
};

export type ReturnDuplicateType = {
  __typename?: 'returnDuplicateType';
  cep: CustomerEngagementPlanning;
  tactics: Array<Tactic>;
};
