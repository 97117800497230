import { useEffect, useRef, useState } from 'react';

const useTooltipList = () => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLDivElement | null>(null);

  const handleTooltipToggle = () => {
    setOpen((prevState) => !prevState);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return {
    open,
    buttonRef,
    handleTooltipToggle,
  };
};

export default useTooltipList;
