import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import useCepFormConfigurationGroupConfig
  from './CepFormConfigurationGroup.config';
import { therapeuticAreaRelativesProducts } from 'basics/constants/common.constants';
import { BiogenProduct } from 'basics/enums/biogen.enums';
import { CepSteps } from 'basics/enums/maya.enums';
import useGetCustomerInitiativeByIdQuery from 'basics/graphql/queries/getCustomerInitiativeById';
import { SelectOption } from 'basics/options/options.types';
import { CepStateType, EventStateType } from 'basics/types/maya.types';
import { formatUTCDate } from 'basics/utils/date.utils';
import { useCurrentUserState } from 'contexts/user';
import { CustomerInitiative } from 'generated/maya.types';
import { useQueryParams } from 'hooks/useQueryParams';
import cepState from 'states/cep.states';
import eventState from 'states/event.states';
import { DateRange } from 'yoda-ui/components/Form/DateRangePicker';
import { useYodaForm } from 'yoda-ui/yodaForm';

const useCepFormConfigurationGroup = (isTemplate: boolean) => {
  const { setValue, setFieldShowError, setStepShowError, useWatchField } = useYodaForm();

  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const eventStateValue = useSnapshot(eventState) as EventStateType;
  const { user } = useCurrentUserState();
  const { parentId, eventId } = useQueryParams();

  const [customerInitiativeId, setCustomerInitiativeId] = useState<string | null>(null);
  const [selectedTA, setSelectedTA] = useState<string | null>(null);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);

  const parentCustomerInitiative = useGetCustomerInitiativeByIdQuery(parentId || null).data;
  const newCustomerInitiative = useGetCustomerInitiativeByIdQuery(customerInitiativeId || null).data;
  const currentCustomerInitiative = newCustomerInitiative
  || cepStateValue.cep?.customerInitiative as CustomerInitiative
  || parentCustomerInitiative;

  const {
    cepFormConfigurationGroup,
  } = useCepFormConfigurationGroupConfig(
    currentCustomerInitiative,
    user,
    selectedTA,
    selectedStartDate,
    selectedEndDate,
    isTemplate,
  );

  const dateKey = useWatchField(cepFormConfigurationGroup.date.name);

  useEffect(() => {
    setSelectedStartDate(dateKey?.value ? dateKey.value[0] : null);
    setSelectedEndDate(dateKey?.value ? dateKey.value[1] : null);
  }, [dateKey]);

  if (Object.keys(cepStateValue.cep || {}).length && !parentId) {
    const fieldsName = Object.keys(cepFormConfigurationGroup);
    setFieldShowError(true, fieldsName);
    setStepShowError(true, [CepSteps.configurationStep]);
  }

  useEffect(() => {
    if (newCustomerInitiative) {
      const dateRange = formatUTCDate(
        newCustomerInitiative?.dateStart,
        eventId ? newCustomerInitiative?.dateEnd : undefined,
      );

      setSelectedTA(newCustomerInitiative?.therapeuticArea || null);
      setValue(cepFormConfigurationGroup.therapeuticArea.name, newCustomerInitiative?.therapeuticArea);
      setValue(cepFormConfigurationGroup.product.name, newCustomerInitiative?.product || null);
      setValue(cepFormConfigurationGroup.biogenFunction.name, newCustomerInitiative?.functionalOwner);
      setValue(cepFormConfigurationGroup.date.name, dateRange);
    }
  }, [
    cepFormConfigurationGroup.biogenFunction.name,
    cepFormConfigurationGroup.product.name,
    cepFormConfigurationGroup.therapeuticArea.name,
    cepFormConfigurationGroup.date.name,
    newCustomerInitiative,
    setValue,
    eventId,
  ]);

  useEffect(() => {
    if (eventStateValue.ciId) {
      setValue(cepFormConfigurationGroup.customerInitiative.name, eventStateValue.ciId);
      setCustomerInitiativeId(eventStateValue.ciId);
    }
  }, [
    cepFormConfigurationGroup.customerInitiative.name,
    eventStateValue.ciId,
    setValue,
  ]);

  useEffect(() => {
    if (selectedStartDate === null && cepStateValue.cep?.dateStart) {
      setSelectedStartDate(new Date(cepStateValue.cep?.dateStart));
    }
  }, [cepStateValue.cep?.dateStart, selectedStartDate]);

  useEffect(() => {
    if (selectedEndDate === null && cepStateValue.cep?.dateEnd) {
      setSelectedEndDate(new Date(cepStateValue.cep?.dateEnd));
    }
  }, [cepStateValue.cep?.dateEnd, selectedEndDate]);

  const handleDateChange = useCallback((date: DateRange) => {
    setSelectedStartDate(date ? date[0] : null);
    setSelectedEndDate(date ? date[1] : null);
  }, []);

  const handleCustomerInitiativeChange = useCallback((event: SyntheticEvent<Element, Event>, value: SelectOption) => {
    if (value?.id) {
      setCustomerInitiativeId(value.id as string);
    }
  }, [setCustomerInitiativeId]);

  const handleChangeTA = useCallback((event: SyntheticEvent<Element, Event>, TAValue: SelectOption | null) => {
    setSelectedTA(TAValue?.label || null);
    if (TAValue === null) {
      setValue(cepFormConfigurationGroup.product.name, []);
    }
  }, [setValue, cepFormConfigurationGroup]);

  const handleChangeProduct = useCallback((event: SyntheticEvent<Element, Event>, product: SelectOption) => {
    if (product) {
      const therapeuticAreaValue = Object.keys(therapeuticAreaRelativesProducts)
        .find((key: string) => therapeuticAreaRelativesProducts[key as keyof typeof therapeuticAreaRelativesProducts]
          ?.includes(product.id as BiogenProduct));
      setValue(cepFormConfigurationGroup.therapeuticArea.name, therapeuticAreaValue);
    }
  }, [setValue, cepFormConfigurationGroup]);

  return {
    cepFormConfigurationGroup,
    handleCustomerInitiativeChange,
    handleChangeTA,
    handleChangeProduct,
    handleDateChange,
  };
};

export default useCepFormConfigurationGroup;
