import { BooleanOptionsValue } from 'basics/enums/maya.enums';
import { Participant } from 'generated/event.types';
import { Target, TargetFilterInput } from 'generated/maya.types';
import { YodaFieldValue } from 'yoda-ui/yodaForm/yodaForm.types';

export const targetsFilterToBE = (data: YodaFieldValue): Partial<TargetFilterInput> => {
  return {
    biosSegments: data?.biosSegments?.length ? data.biosSegments : null,
    businessUnits: data.businessUnit ? [data.businessUnit] : null,
    countries: data.country ? [data.country] : null,
    imsSegments: data?.imsSegments?.length ? data.imsSegments : null,
    smaSegments: data?.smaSegments?.length ? data.smaSegments : null,
    specialties: data.specialty.length ? data.specialty : null,
    types: data.type.length ? data.type : null,
    hcoNames: data.hcoNames.length ? data.hcoNames : null,
    consent: data.consent.length ? data.consent : null,
    cyclePlan: data.cyclePlan !== '' ? data.cyclePlan === BooleanOptionsValue.true : null,
    channelAffinity: {
      approvedEmails: data.approvedEmailsAffinity.length ? data.approvedEmailsAffinity : null,
      broadcastEmails: data.broadcastEmailsAffinity.length ? data.broadcastEmailsAffinity : null,
      f2f: data.f2fAffinity.length ? data.f2fAffinity : null,
      f2fEvent: data.f2fEventAffinity.length ? data.f2fEventAffinity : null,
      phone: data.phoneAffinity.length ? data.phoneAffinity : null,
      virtual: data.virtualAffinity.length ? data.virtualAffinity : null,
      virtualEvent: data.virtualEventAffinity.length ? data.virtualEventAffinity : null,
      webSessions: data.webSessionsAffinity.length ? data.webSessionsAffinity : null,
      allDigital: data.allDigitalAffinity.length ? data.allDigitalAffinity : null,
      allF2F: data.allF2FAffinity.length ? data.allF2FAffinity : null,
    },
  };
};

export const targetsToBE = (data: Participant): Pick<Target, 'firstName' | 'lastName' | 'email' | 'mdmId'> => {
  return {
    mdmId: data.mdmId || '',
    email: data.email || '',
    firstName: data.firstName,
    lastName: data.lastName,
  };
};

