import { ApolloError, gql, useQuery } from '@apollo/client';
import { eventFragment } from '../fragments/event.fragments';
import { EVENT_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { Event } from 'generated/event.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_EVENT_BY_ID = gql`
  query GetFullPrivateEventById($eventId: ObjectId!) {
    getFullPrivateEventById(eventId: $eventId) {
      ${eventFragment}
    }
  }
`;

type ResultType = {
  getFullPrivateEventById: Event;
};

type UseGetEventById = (eventId: string | null, skip?: boolean) => {
  loading: boolean;
  error?: ApolloError;
  data?: Event;
};

const useGetEventByIdQuery: UseGetEventById = (eventId, skip = false) => {
  const { data, loading, error } = useQuery<ResultType>(GET_EVENT_BY_ID, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: EVENT_GRAPHQL_ENDPOINT },
    skip,
    variables: {
      eventId,
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getEventById.ts - useGetEventByIdQuery',
    });
  }

  return {
    data: data?.getFullPrivateEventById,
    loading,
    error,
  };
};

export default useGetEventByIdQuery;
