import { proxy } from 'valtio';
import { EventStateType } from 'basics/types/maya.types';

const eventState = proxy<EventStateType>({
  event: {
    title: '',
    country: '',
    product: '',
    startDate: '',
    endDate: '',
    dataRange: [],
  },
  eventId: '',
  ciId: '',
});

export default eventState;
