import { useEffect, useState } from 'react';
import { Tactic } from 'generated/maya.types';
import { MiViewColors } from 'miview-ui/miviewTheme';

const useMiViewTargetListRow = (tactics: Tactic[]) => {
  const [groupedTactics, setGroupedTactics] = useState<Tactic[][]>();

  const hasWhiteSpace = (s: string) => {
    return s.indexOf(' ') >= 0;
  };

  const hasDash = (s: string) => {
    return s.indexOf('-') >= 0;
  };

  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  };

  const stringAvatar = (firstName: string | null = '', lastName: string | null = '') => {
    const isFullName = firstName && lastName;
    const name = `${firstName || ''} ${lastName || ''}`;

    try {
      const whiteSpaceChar = hasWhiteSpace(name);
      const dashChar = hasDash(name);
      let charArray = name.slice(0, 1);

      if (!isFullName) {
        charArray = name && name.replace(/\s+/g, '')[0];
      } else if (whiteSpaceChar) {
        charArray = name.split(' ')[0][0] + name.split(' ')[1][0];
      } else if (dashChar) {
        charArray = name.split('-')[0][0] + name.split('-')[1][0];
      }

      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${charArray.toUpperCase()}`,
      };
    } catch (e) {
      return {
        sx: {
          bgcolor: MiViewColors.gray,
        },
        children: `${''}`,
      };
    }
  };

  useEffect(() => {
    const uniqueGroupedTactics = tactics.reduce<Tactic[][]>((groups, currentGroup) => {
      const lastGroup = groups[groups.length - 1];

      if (lastGroup.length === 7) {
        groups.push([currentGroup]);
      } else {
        lastGroup.push(currentGroup);
      }

      return groups;
    }, [[]]);

    setGroupedTactics(uniqueGroupedTactics);
  }, [tactics]);

  return {
    groupedTactics,
    stringAvatar,
  };
};

export default useMiViewTargetListRow;
