import { useEffect } from 'react';
import { getConfigVar } from '../../config/config';
import useAuth from 'hooks/useAuth';
import { useQueryParams } from 'hooks/useQueryParams';

const Login = () => {
  const { error } = useQueryParams();
  const { handleLogin } = useAuth();

  useEffect(() => {
    const isAccessDenied = error === 'access_denied';

    if (isAccessDenied) {
      window.location.href = getConfigVar('REACT_APP_BACKOFFICE_LOGIN_URI');
    } else {
      handleLogin();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return <></>;
};

export default Login;
