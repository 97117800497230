import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { YodaColors, YodaFontSize, YodaSpacing } from 'yoda-ui/yodaTheme';

export const CustomContentCardStyle: SxProps<Theme> = {
  padding: '1rem',
  border: 'none',
  boxShadow: `0px 5px 20px ${YodaColors.gray2}`,
};

export const TacticChannelGroupStyle: SxProps<Theme> = {

  textAlign: 'left',
  mr: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'auto',
  height: '20px',
  borderRadius: '30px',
  color: YodaColors.white,
  fontSize: YodaFontSize.xSmall,
  paddingLeft: YodaSpacing.xxSmall,
  paddingRight: YodaSpacing.xxSmall,
  paddingTop: YodaSpacing.xxxxSmall,
  paddingBottom: YodaSpacing.xxxxSmall,
};

export const TacticCardVeevaStatusStyle: SxProps<Theme> = {
  textAlign: 'right',
  ml: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'auto',
  height: '20px',
  borderRadius: '30px',
  fontSize: YodaFontSize.xSmall,
  paddingLeft: YodaSpacing.xxSmall,
  paddingRight: YodaSpacing.xxSmall,
  paddingTop: YodaSpacing.xxxxSmall,
  paddingBottom: YodaSpacing.xxxxSmall,
};

export const TacticGroupBoxStyle: SxProps<Theme> = {
  ...TacticChannelGroupStyle,
  marginRight: 0,
};

export const TacticCardTitleStyle: SxProps<Theme> = {
  marginBottom: YodaSpacing.xxSmall,
  fontSize: YodaFontSize.small,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  wordWrap: 'break-word',
};

export const TacticCardFontStyle: SxProps<Theme> = {

  fontWeight: 'lighter',
  fontSize: YodaFontSize.xSmall,
  marginBottom: YodaSpacing.xxxSmall,
  color: YodaColors.gray4,
};

export const clickableIdStyle: SxProps<Theme> = {

  fontWeight: 'lighter',
  fontSize: YodaFontSize.xSmall,
  marginBottom: YodaSpacing.xxxSmall,
  color: YodaColors.gray4,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  wordWrap: 'break-word',

  '& .MuiSvgIcon-root': {
    height: YodaFontSize.small,
  },
};

export const iconsStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'end',
  gap: '10px',

  marginTop: YodaSpacing.medium,
  color: YodaColors.white,

  '& .MuiSvgIcon-root': {
    maxWidth: '20px',
    maxHeight: '20px',
    backgroundColor: YodaColors.primaryBlue,
    padding: '5px',
    borderRadius: '20px',
  },

  '& .MuiSvgIcon-root:hover': {
    cursor: 'pointer',
    backgroundColor: YodaColors.primaryBlueHover,
  },
};

export const tacticGroupIconStyle: SxProps<Theme> = {
  fontSize: YodaFontSize.xSmall,
};
