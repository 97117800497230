import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { YodaFontSize } from 'yoda-ui/yodaTheme';

export const listItemStyle: SxProps<Theme> = {
  width: '200px',
  '& .MuiListItemText-primary': {
    fontSize: YodaFontSize.xSmall,
  },
};
