import { FC, useCallback, useState, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import useCustomerInitiativeTypeOptions from 'basics/options/customerInitiative.options';
import { SelectOption } from 'basics/options/options.types';
import { transformInputDateUTC } from 'basics/utils/dates/dates.utils';
import { useGetConfig } from 'basics/utils/getConfig.hook';
import ActionButton from 'components/ActionButton';
import { useCurrentUserState } from 'contexts/user';
import Box from 'yoda-ui/components/Box';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import Dialog from 'yoda-ui/components/Dialogs/Dialog';
import DialogContent from 'yoda-ui/components/Dialogs/Dialog/DialogContent';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import { useYodaCreateForm, YodaFormProvider } from 'yoda-ui/yodaForm';
import { YodaBorderRadius, YodaFontWeight, YodaJustifyContent, YodaSpacing } from 'yoda-ui/yodaTheme';

export type CiDialogProps = {
  open: boolean;
  dateRange?: (Date | string)[];
  onClickNext: (id: string) => void;
  onClickNew: () => void;
};

const dialogStyle = {
  '& .MuiDialog-paper': {
    justifyContent: YodaJustifyContent.spaceAround,
    padding: YodaSpacing.xxLarge,
  },
};

const CiDialog: FC<CiDialogProps> = ({ open, onClickNext, onClickNew, dateRange }) => {
  const { t } = useTranslation();
  const { user } = useCurrentUserState();
  const yodaFormMethods = useYodaCreateForm({ setIsValidOptional: true });
  const currentModuleConfig = useGetConfig();
  const [customerInitiativeId, setCustomerInitiativeId] = useState('');
  const dateForCI = transformInputDateUTC(dateRange || []);
  const options = useCustomerInitiativeTypeOptions(
    {
      isTemplate: false,
      countries: user?.country ? [user.country] : undefined,
      dateRange: dateRange?.length !== 0 ? [dateForCI.dateStart, dateForCI.dateEnd] : undefined,
    },
  );

  const handleCustomerInitiativeChange = useCallback((event: SyntheticEvent<Element, Event>, value: SelectOption) => {
    setCustomerInitiativeId(value?.id ? value.id as string : '');
  }, [setCustomerInitiativeId]);

  return (
    <Dialog
      open={ open }
      borderRadius={ YodaBorderRadius.xSmall }
      sx={ dialogStyle }
      minWidth="650px"
      minHeight="550px"
    >
      <Box display="flex" justifyContent="center">
        <img
          src={ currentModuleConfig?.logo }
          width={ 300 }
          alt="maya-logo"
          style={ { height: 'inherit' } }
        />
      </Box>
      <Box>
        <DialogContent fontWeight={ YodaFontWeight.light }>
          <YodaFormProvider { ...yodaFormMethods.providerFields }>
            <SelectYoda
              options={ options }
              label={ t('maya_cep_step_configuration_customer_initiative_label') }
              placeholder={ t('maya_cep_step_configuration_customer_initiative_label') }
              name="customerInitiative"
              maxHeight={ 200 }
              onChange={ handleCustomerInitiativeChange }
            ></SelectYoda>
          </YodaFormProvider>
        </DialogContent>
      </Box>
      <Box display="flex" justifyContent="space-around">
        <ActionButton
          buttonProps={
            {
              buttonType: ButtonType.primary,
              onClick: () => onClickNew(),
            }
          }
          label="Create new"
        />
        <ActionButton
          buttonProps={
            {
              buttonType: ButtonType.primary,
              disabled: !customerInitiativeId,
              onClick: () => onClickNext(customerInitiativeId),
            }
          }
          label="Proceed"
        />
      </Box>
    </Dialog>
  );
};

export default CiDialog;
