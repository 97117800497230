import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { TacticCardVeevaStatusStyle, TacticChannelGroupStyle, TacticGroupBoxStyle } from './TacticCard.styles';
import { Channel, TacticMode, TacticTabsType, VeevaSyncStatus } from 'basics/enums/maya.enums';
import { useSetTacticStatusToDeletedMutation } from 'basics/graphql/mutations/setTacticStatusToDeleted';
import { CepStateType } from 'basics/types/maya.types';
import { errorToast, succesToast, warningToast } from 'basics/utils/toast';
import { Tactic } from 'generated/maya.types';
import cepState from 'states/cep.states';
import tacticState from 'states/tactic.states';
import { YodaColors } from 'yoda-ui/yodaTheme';

const useTacticCard = (tactic: Tactic) => {
  const { t } = useTranslation();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const [setTacticStatusToDeleted, { loading: removeTacticLoading }] = useSetTacticStatusToDeletedMutation();
  const [tacticGroup] = useState(cepStateValue.tacticGroups.find((group) => group._id === tactic.previousTactic?._id));

  const isChannelMatch = (channel: Channel) => {
    return Object.keys(Channel).find((key) => Channel[key as keyof typeof Channel] === channel);
  };

  const getCategoryForChannel = (channel: Channel) => {
    switch (channel) {
      case isChannelMatch(Channel.approvedEmail):
      case isChannelMatch(Channel.broadcastEmail):
        return TacticTabsType.EMAIL;
      case isChannelMatch(Channel.virtualEvent):
      case isChannelMatch(Channel.f2fEvent):
      case isChannelMatch(Channel.hybridEvent):
        return TacticTabsType.EVENT;
      case isChannelMatch(Channel.f2fMeeting):
      case isChannelMatch(Channel.oneToOne):
      case isChannelMatch(Channel.virtualMeeting):
      case isChannelMatch(Channel.telephone):
        return TacticTabsType.INTERACTION;
      case isChannelMatch(Channel.webLogIn):
      case isChannelMatch(Channel.webNoLogIn):
        return TacticTabsType.WEB;
      default:
        return null;
    }
  };

  const getStyleFromCategory = (group: TacticTabsType | null, channel: keyof typeof Channel) => {
    switch (group) {
      case TacticTabsType.EMAIL:
        return {
          ...TacticChannelGroupStyle,
          backgroundColor: channel === 'approvedEmail'
            ? YodaColors.greenSuccessDark
            : YodaColors.green2,
        };
      case TacticTabsType.EVENT:
        return {
          ...TacticChannelGroupStyle,
          backgroundColor: YodaColors.yellow,
        };
      case TacticTabsType.INTERACTION:
        return {
          ...TacticChannelGroupStyle,
          backgroundColor: YodaColors.purple,
        };
      case TacticTabsType.WEB:
        return {
          ...TacticChannelGroupStyle,
          backgroundColor: YodaColors.primaryBlue,
        };
      default:
        return null;
    }
  };

  const generateHexColorByNumber = (number: number, contrast: number): string => {
    const r = ((number + contrast) * 100 + 50) % 256;
    const g = ((number + contrast) * 200 + 100) % 256;
    const b = ((number + contrast) * 300 + 150) % 256;

    const hexColor = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;

    return hexColor.toUpperCase();
  };

  const getStylesFromNumberGroup = (groupNumber: number | null) => {
    const defaultContrast = 25;
    const colorNumber = groupNumber !== null ? groupNumber : 0;

    return {
      ...TacticGroupBoxStyle,
      backgroundColor: generateHexColorByNumber(colorNumber, defaultContrast),
    };
  };

  const getStyleFromVeevaSyncStatus = (status: VeevaSyncStatus) => {
    switch (status) {
      case VeevaSyncStatus.ERROR:
        return {
          ...TacticCardVeevaStatusStyle,
          border: `solid ${YodaColors.errorLight}`,
          color: YodaColors.error,
          backgroundColor: YodaColors.errorLight,
        };
      case VeevaSyncStatus.PENDING:
        return {
          ...TacticCardVeevaStatusStyle,
          border: `solid ${YodaColors.warningLight}`,
          color: YodaColors.warning,
          backgroundColor: YodaColors.warningLight,
        };
      case VeevaSyncStatus.SYNC:
        return {
          ...TacticCardVeevaStatusStyle,
          border: `solid ${YodaColors.greenSuccessLight}`,
          color: YodaColors.greenSuccessDark,
          backgroundColor: YodaColors.greenSuccessLight,
        };
      default:
        return null;
    }
  };

  const extractNumberFromText = (text: string | undefined): number | null => {
    if (!text) return null;

    const matches = text.match(/\d+/g);
    return matches ? parseInt(matches[matches.length - 1], 10) : null;
  };

  const editTacticCallback = () => {
    tacticState.tactic = tactic;
    tacticState.mode = TacticMode.form;
  };

  const deleteTacticCallback = async (tacticId: string) => {
    const { data: tacticDeleted, error: tacticdDeletedError } = await setTacticStatusToDeleted(tacticId);
    if (tacticDeleted && !tacticdDeletedError) {
      succesToast(t('maya_action_deleting_tactic_success'));
      const clonedTactics = cloneDeep(cepStateValue.tactics);
      const targetTacticsIndex = clonedTactics.findIndex((element) => { return element._id === tacticId; });
      if (targetTacticsIndex !== -1) {
        clonedTactics.splice(targetTacticsIndex, 1);
        cepState.tactics = clonedTactics;
      }
    } else {
      warningToast(tacticdDeletedError || t('errors_standard'));
    }

    setOpenDeleteModal(false);
  };

  const openDeleteModalCallback = (tacticId: string) => {
    setOpenDeleteModal(true);
    if (tacticState.tactic) {
      tacticState.tactic._id = tacticId;
    }
  };

  const handleCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      succesToast(t('maya_successfull_copying', { text }));
    } catch (error) {
      errorToast(error as string);
    }
  };

  return {
    deleteTacticCallback,
    editTacticCallback,
    getCategoryForChannel,
    getStyleFromCategory,
    getStyleFromVeevaSyncStatus,
    handleCopy,
    openDeleteModal,
    openDeleteModalCallback,
    removeTacticLoading,
    setOpenDeleteModal,
    extractNumberFromText,
    tacticGroup,
    getStylesFromNumberGroup,
  };
};

export default useTacticCard;
