import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { CAMPAIGN_YEAR } from 'basics/constants/common.constant';
import { mayaCepDuplicateRoute, mayaCustomerInitiativeListRoute } from 'basics/constants/routes.constants';
import { useCreateCustomerInitiativeMutation } from 'basics/graphql/mutations/createCustomerInitiative';
import useGetEventByIdQuery from 'basics/graphql/queries/getEventById';
import useGetStrategicImperativeByIdQuery from 'basics/graphql/queries/getStrategicImperativeById';
import { eventToEventState } from 'basics/transformers/Event.transformer';
import { EventStateType } from 'basics/types/maya.types';
import { succesToast, warningToast } from 'basics/utils/toast';
import { CiFormSubmitCallbackType } from 'components/CustomerInitiativeForm/CustomerInitiativeForm.types';
import { useGTM } from 'contexts/gtm';
import { useQueryParams } from 'hooks/useQueryParams';
import eventState from 'states/event.states';

const useCustomerInitiativeCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dataLayer = useGTM();

  const eventStateValue = useSnapshot(eventState) as EventStateType;

  const [createCustomerInitiative] = useCreateCustomerInitiativeMutation();
  const { cepSourceId, eventId, parentId } = useQueryParams();

  const [loading, setLoading] = useState(true);

  const { data: event, loading: loadingEvent } = useGetEventByIdQuery(eventId || null, !eventId || !!eventStateValue.eventId);
  const parentStrategicImperative = useGetStrategicImperativeByIdQuery(parentId).data;

  useEffect(() => {
    if (!loadingEvent && event) {
      eventState.event = eventToEventState(event);
      eventState.eventId = eventId;
      setLoading(false);
    }
  }, [loadingEvent, event, eventId]);

  useEffect(() => {
    if (parentStrategicImperative || !parentId) {
      setLoading(false);
    }
  }, [parentId, parentStrategicImperative, setLoading]);

  const handleCancelCustomerInitiativeForm = () => {
    navigate(`${mayaCustomerInitiativeListRoute}?year=${CAMPAIGN_YEAR}`);
  };

  const handleSaveCustomerInitiativeForm: CiFormSubmitCallbackType = async (ci) => {
    const { data: customerInitiativeData, error } = await createCustomerInitiative({ ...ci, isTemplate: false });
    const year = new Date(ci.dateStart).getFullYear();
    const route = !eventId
      ? `${mayaCustomerInitiativeListRoute}?year=${year}`
      : `${mayaCepDuplicateRoute}?eventId=${eventId}&cepSourceId=${cepSourceId}`;

    if (eventId && customerInitiativeData?._id) {
      eventState.ciId = customerInitiativeData._id;
    }

    if (customerInitiativeData && !error) {
      dataLayer({
        event: 'customer_initiative',
        step: 'initiation',
      });
      succesToast(t('maya_ci_created', { title: ci.title }));
      navigate(route);
    } else {
      warningToast(error || t('errors_standard'));
      navigate(route);
    }
  };

  return {
    loading,
    loadingEvent,
    parentStrategicImperative,
    handleSaveCustomerInitiativeForm,
    handleCancelCustomerInitiativeForm,
  };
};

export default useCustomerInitiativeCreate;
