import { addDays, startOfDay, subDays } from 'date-fns';
import { targetsToBE } from './Target.transformer';
import { eventProduct } from 'basics/constants/common.constants';
import { EventProduct } from 'basics/enums/event.enums';
import { EventState } from 'basics/types/maya.types';
import { Event } from 'generated/event.types';

export const eventToEventState = (event: Event): EventState => {
  const { startDate = '', endDate = '' } = event.data?.session ? event.data.session : {};
  const dateStart = startOfDay(new Date(startDate));
  const dateEnd = startOfDay(new Date(endDate));
  const prcCodeEventDetails = event.data?.biogenLincConfig?.prcCodeEventDetails;

  return {
    title: event.title,
    country: event?.originMarket?.toUpperCase() || '',
    product: event.data?.product ? eventProduct[event.data.product as EventProduct] : '',
    startDate: dateStart,
    endDate: dateEnd,
    assetName: prcCodeEventDetails && prcCodeEventDetails?.length <= 255 ? prcCodeEventDetails : '',
    eventId: event.externalId || '',
    eventURL: event.data?.biogenLincConfig?.detailsPageUrl || '',
    targets: event.data?.participants?.map(targetsToBE) || [],
    dataRange: [subDays(dateStart, 14), addDays(dateEnd, 14)],
  };
};
