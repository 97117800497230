import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { YodaColors } from 'yoda-ui/yodaTheme';

export const kpiStyle: SxProps<Theme> = {
  '& .kpi-theme': {
    border: `solid 1px ${YodaColors.primaryBlue}`,
  },
  '& .list-channel-cell': {
    whiteSpace: 'normal',
  },
  '& .list-group-cell': {
    whiteSpace: 'normal',
  },
};
