const dataFragment = `
  __typename
  product
  session {
    startDate
    endDate
  }
  biogenLincConfig {
    prcCodeEventDetails
    detailsPageUrl
  }
  participants {
    email
    firstName
    lastName
    mdmId
  }
`;

export const eventFragment = `
  title
  originMarket
  data {
    ...on OnlineEvent {
      ${dataFragment}
    }
    ...on PhysicalEvent {
      ${dataFragment}
    }
    ...on HybridEvent {
      ${dataFragment}
    }
  }
  externalId
  presence
`;
