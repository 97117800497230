import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { targetListFragment } from 'basics/graphql/fragments/targetList.fragments';
import { MutationReturnType } from 'basics/types/maya.types';
import { Target } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export type BulkCreateTargetListResponse = {
  bulkCreateTargetList: Target[];
};

export type UseBulkCreateTargetListMutation = [
  (
    cepId: string,
    targets: Pick<Target, 'firstName' | 'lastName' | 'email' | 'mdmId'>[],
  ) => Promise<MutationReturnType<Target[]>>,
  MutationResult<BulkCreateTargetListResponse>,
];

export const BulkCreateTargetListMutation = gql`
mutation BulkCreateTargetList($cepId: String!, $targets: [TargetInput!]!) {
  bulkCreateTargetList(cepId: $cepId, targets: $targets){
    ${targetListFragment}
  }
}`;

export const useBulkCreateTargetListMutation = (): UseBulkCreateTargetListMutation => {
  const [createTargetListFromCSVMutation, state] = useMutation<BulkCreateTargetListResponse>(BulkCreateTargetListMutation, {
    fetchPolicy: 'network-only',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  const executeMutation = useCallback(async (cepId, targets) => {
    try {
      const { data } = await createTargetListFromCSVMutation({
        variables: {
          cepId,
          targets,
        },
      });

      return { data: data?.bulkCreateTargetList || null, error: null };
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'bulkTargetList.ts - useBulkTargetListMutation',
        });

        return { data: null, error: customContext.message as string };
      }

      return { data: null, error: null };
    }
  }, [createTargetListFromCSVMutation]);

  return [executeMutation, state];
};
