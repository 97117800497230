import { CDN_DNS, MODULES_LOGO_PATH } from './images.constants';
import { mayaTacticPrintRoute } from 'basics/constants/routes.constants';
import { BiogenProduct, BiogenTherapeuticArea } from 'basics/enums/biogen.enums';
import { EventProduct } from 'basics/enums/event.enums';
import { ConfigModule } from 'basics/enums/general.enums';

export const LAUNCH_DARKLY_COMMON_USER_KEY = 'common';
export const BACKOFFICE_TITLE = 'BiogenLinc Backoffice';
export const APP_TITLE = `Biogen ${BACKOFFICE_TITLE}`;
export const SUPPORT_EMAIL = 'https://reqform.mybiogen.net/#/other-requests/other';
export const cdnTemplatesPath = `https://${CDN_DNS}/templates`;
export const cdnDocumentsPath = `https://${CDN_DNS}/documents`;
export const TIME_60_MINUTES_IN_MS = 60 * 60 * 1000;
export const TIME_ONE_MINUTE_IN_SECONDS = 60;
export const TIME_FIVE_MINUTES_IN_SECONDS = 5 * 60;
export const TIME_ONE_SECOND_IN_MS = 1000;
export const TIME_ONE_SECOND_IN_SECONDS = 1;
export const appBarHeight = '6rem';

export const MAYA_TITLE = 'BiogenLinc Maya';
export const SUPPORT_MAYA_EMAIL = 'https://reqform.mybiogen.net/#/other-requests/other';
export const MAYA_GRAPHQL_ENDPOINT = 'maya_endpoint';
export const EVENT_GRAPHQL_ENDPOINT = 'event_endpoint';
export const MAYA_TARGETS_TEMPLATE = `${cdnTemplatesPath}/backOffice/templateTargetList.csv`;

export const MODULES_CONFIG = {
  [ConfigModule.Default]: {
    logo: `${MODULES_LOGO_PATH}/new_maya_logo.png`,
    module: 'maya',
    path: '/maya',
    support: SUPPORT_MAYA_EMAIL,
    title: MAYA_TITLE,
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
    enableCountrySelector: true,
    noLayoutURL: [mayaTacticPrintRoute],
  },
};

const TAListProducts = {
  [BiogenTherapeuticArea.ALZHEIMER]: [
    BiogenProduct.Aducanumab,
    BiogenProduct.Alzheimer,
  ],
  [BiogenTherapeuticArea.BIOSIMS]: [
    BiogenProduct.Benepali,
    BiogenProduct.Biosimilar,
    BiogenProduct.Byooviz,
    BiogenProduct.Flixabi,
    BiogenProduct.Imraldi,
  ],
  [BiogenTherapeuticArea.FA]: [
    BiogenProduct.FA,
    BiogenProduct.Omaveloxolone,
    BiogenProduct.Skyclarys,
  ],
  [BiogenTherapeuticArea.MS]: [
    BiogenProduct.Avonex,
    BiogenProduct.Fampyra,
    BiogenProduct.MS_Portfolio,
    BiogenProduct.Plegridy_IM,
    BiogenProduct.Plegridy,
    BiogenProduct.Tecfidera,
    BiogenProduct.Tysabri_IV,
    BiogenProduct.Tysabri,
    BiogenProduct.Vumerity,
  ],
  [BiogenTherapeuticArea.NONE]: [
    BiogenProduct.Biogen,
  ],
  [BiogenTherapeuticArea.SMA]: [
    BiogenProduct.SMA,
    BiogenProduct.Spinraza,
  ],
};

export const eventProduct = {
  [EventProduct.Aducanumab]: BiogenProduct.Aducanumab,
  [EventProduct.Alzheimer]: BiogenProduct.Alzheimer,
  [EventProduct.Avonex]: BiogenProduct.Avonex,
  [EventProduct.Benepali]: BiogenProduct.Benepali,
  [EventProduct.Biogen]: BiogenProduct.Biogen,
  [EventProduct.Biosimilar]: BiogenProduct.Biosimilar,
  [EventProduct.Byooviz]: BiogenProduct.Byooviz,
  [EventProduct.FA]: BiogenProduct.FA,
  [EventProduct.Imraldi]: BiogenProduct.Imraldi,
  [EventProduct.MS]: BiogenProduct.MS_Portfolio,
  [EventProduct.Omaveloxolone]: BiogenProduct.Omaveloxolone,
  [EventProduct.SMA]: BiogenProduct.SMA,
  [EventProduct.Skyclarys]: BiogenProduct.Skyclarys,
  [EventProduct.Spinraza]: BiogenProduct.Spinraza,
  [EventProduct.Tecfidera]: BiogenProduct.Tecfidera,
  [EventProduct.Tysabri_IV]: BiogenProduct.Tysabri_IV,
  [EventProduct.Tysabri]: BiogenProduct.Tysabri,
  [EventProduct.Vumerity]: BiogenProduct.Vumerity,
};

export const therapeuticAreaRelativesProducts = Object.seal(TAListProducts);

