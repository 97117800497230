export enum EventProduct {
  Aducanumab = 'aducanumab',
  Avonex = 'avonex',
  Alzheimer = 'alzheimer',
  Benepali = 'benepali',
  Biogen = 'biogen',
  Biosimilar = 'biosimilar',
  Byooviz = 'byooviz',
  FA = 'fa',
  Imraldi = 'imraldi',
  MS = 'ms',
  Omaveloxolone = 'omaveloxolone',
  SMA = 'sma',
  Skyclarys = 'skyclarys',
  Spinraza = 'spinraza',
  Tecfidera = 'tecfidera',
  Tysabri = 'tysabri sc',
  Tysabri_IV = 'tysabri iv',
  Vumerity = 'vumerity',
}
