import { MoreVert } from '@mui/icons-material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import BarChartIcon from '@mui/icons-material/BarChart';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useMiViewScreenTarget from './MiViewScreenTarget.hook';
import { enabledIconStyle, disabledIconStyle, boxStyle } from './MiViewScreenTarget.style';
import MiViewScreen from 'components/MiViewScreen/MiViewScreen';
import MiViewScreenContent from 'components/MiViewScreenContent/MiViewScreenContent';
import MiViewScreenFilterMenu from 'components/MiViewScreenFilterMenu/MiViewScreenFilterMenu';
import MiViewScreenHeader from 'components/MiViewScreenHeader/MiViewScreenHeader';
import MiViewScreenTitle from 'components/MiViewScreenTitle/MiViewScreenTitle';
import MiViewTargetList from 'components/MiViewTargetList/MiViewTargetList';
import MiViewPreloader from 'miview-ui/components/MiViewPreloader';

const MiViewScreenTarget: FC = () => {
  const { t } = useTranslation();

  const {
    tacticFieldValues,
    targets,
    cepTitle,
    tactics,
    selectedTactic,
    nonChildTactics,
    updateFilterField,
    clearFilterField,
    screenLoading,
  } = useMiViewScreenTarget();

  return (
    <MiViewScreen>
      <MiViewScreenHeader>
        <ArrowBackIosNewIcon sx={ disabledIconStyle } />
        <MiViewScreenTitle componentName={ cepTitle || '' } maxWidth={ 220 }></MiViewScreenTitle>
        {
          tacticFieldValues?.length > 0 && (
            <MiViewScreenFilterMenu
              minWidth={ 120 }
              name={ t('maya_cep_step_miview_preview_filter_menu_tactic_name') }
              filterField={
                {
                  name: t('maya_cep_step_miview_preview_filter_menu_account_name'),
                  sortStatus: 'desc',
                  fieldValues: tacticFieldValues,
                }
              }
              updateFilterField={ updateFilterField }
              clearFilterField={ clearFilterField }>
            </MiViewScreenFilterMenu>
          )
        }
        <ChangeCircleOutlinedIcon sx={ { ...enabledIconStyle, marginLeft: tacticFieldValues?.length ? '0' : 'auto' } }/>
        <BarChartIcon sx={ disabledIconStyle }/>
        <SortIcon sx={ enabledIconStyle }/>
        <FilterListIcon sx={ enabledIconStyle }/>
        <MoreVert sx={ disabledIconStyle } />
      </MiViewScreenHeader>
      <MiViewScreenContent>
        {
          !screenLoading && targets.length > 0
            && <MiViewTargetList
              targets={ targets }
              tactics={ tactics }
              nonChildTactics={ nonChildTactics }
              selectedTactic={ selectedTactic }>
            </MiViewTargetList>
        }
        {
          !screenLoading && !targets.length ? (
            <Box sx={ boxStyle }>
              { t('maya_cep_step_miview_preview_target_screen_no_data') }
            </Box>
          ) : null
        }
        {
          screenLoading
            && <MiViewPreloader size={ 50 }></MiViewPreloader>
        }
      </MiViewScreenContent>
    </MiViewScreen>
  );
};

export default MiViewScreenTarget;
