/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { MoreVert, PersonOutline } from '@mui/icons-material';
import {
  ListItem,
  ListItemText,
  Avatar,
  Divider,
  Chip,
  IconButton,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useMiViewTargetListRow from './MiViewTargetListRow.hook';
import {
  listItemStyle,
  listItemTextStyle,
  chipStyle,
  mainWrapperStyle,
  divCol1Style,
  divCol1ChildStyle,
  divCol2Style,
  divCol3Style,
  divCol3ChildStyle,
  divSpacer,
  firstBadgeStyle,
  secondBadgeStyle,
  secondChipStyle,
  chipListStyle,
  iconButtonStyle,
  avatarStyle,
} from './MiViewTargetListRow.style';
import { TargetListRowProps } from './MiViewTargetListRow.types';
import MiViewBadgeAvatar from 'components/MiViewBadgeAvatar/MiViewScreenBadgeAvatar';

const MiViewTargetListRow: FC<TargetListRowProps> = ({ target, style, tactics, selectedTactic }) => {
  const { t } = useTranslation();
  const {
    groupedTactics,
    stringAvatar,
  } = useMiViewTargetListRow(tactics);

  return (
    <div style={ { ...style, ...mainWrapperStyle } }>
      <ListItem
        sx={ listItemStyle }
        key={ selectedTactic?._id }
        alignItems="center"
        disablePadding
      >
        <div style={ divCol1Style }>
          <div style={ divCol1ChildStyle }>
            <MiViewBadgeAvatar
              tooltipMessage={ t('maya_cep_step_miview_preview_tooltip') } cursor={ 'pointer' }
              badgeList={
                [
                  {
                    content: t('maya_cep_step_miview_preview_badge_avatar_default_content'),
                    textColor: firstBadgeStyle.color,
                    backgroundColor: firstBadgeStyle.background,
                    location: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    invisible: false,
                  },
                  {
                    content: '0%',
                    textColor: secondBadgeStyle.color,
                    backgroundColor: secondBadgeStyle.background,
                    location: {
                      vertical: 'bottom',
                      horizontal: 'right',
                    },
                    invisible: false,
                  },
                ]
              }
            >
              {
                target?.firstName || target?.lastName
                  ? (
                    <Avatar { ...stringAvatar(target.firstName, target.lastName) } />
                  )
                  : (
                    <Avatar sx={ avatarStyle }>
                      <PersonOutline />
                    </Avatar>
                  )
              }
            </MiViewBadgeAvatar>
          </div>
          <div
            style={ divCol2Style }
          >
            <ListItemText
              sx={ listItemTextStyle }
              primary={ `${target?.firstName} ${target?.lastName}` }
            />
          </div>
          <div
            style={ divCol3Style }
          >
            <ListItemText
              sx={ listItemTextStyle }
              primary={
                <div
                  style={ divCol3ChildStyle }
                >
                  {
                    groupedTactics?.map((group, i) => (
                      <div
                        key={ i }
                        style={ chipListStyle }
                      >
                        {
                          group.map(({ tacticId }, j) => (
                            <Chip
                              key={ j }
                              sx={
                                { ...chipStyle,
                                  border: selectedTactic?.tacticId === tacticId ? '2px solid orange' : '0px solid orange' }
                              }
                              label={ `T${i * 7 + (j + 1)}` }
                              size="small"
                              variant="filled"
                            />
                          ))
                        }
                      </div>))
                  }
                </div>
              }
            />

            <Chip
              label={ t('maya_cep_step_miview_preview_target_screen_default_segment') }
              size="small"
              variant="outlined"
              sx={ secondChipStyle }
            />

            <IconButton edge="end" sx={ iconButtonStyle } disabled={ true }>
              <MoreVert />
            </IconButton>
          </div>
        </div>
        <div style={ divSpacer } />
      </ListItem>
      <Divider />
    </div>
  );
};

export default MiViewTargetListRow;
