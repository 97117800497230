import { therapeuticAreaRelativesProducts } from 'basics/constants/common.constants';
import { BiogenProduct, BiogenTherapeuticArea } from 'basics/enums/biogen.enums';

export const getTherapeuticAreaByProduct = (product?: BiogenProduct): string | undefined => {
  if (!product) {
    return '';
  }

  const keys = Object.keys(therapeuticAreaRelativesProducts);
  const therapeuticArea = keys.find((key) => {
    if (therapeuticAreaRelativesProducts[key as BiogenTherapeuticArea].includes(product)) {
      return key;
    }

    return false;
  });

  return therapeuticArea;
};
