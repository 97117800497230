import { FC } from 'react';
import useTooltipList from './TooltipList.hook';
import { listItemStyle } from './TooltipList.style';
import { TooltipListProps } from './TooltipList.types';
import Box from 'yoda-ui/components/Box';
import List from 'yoda-ui/components/List';
import ListItem from 'yoda-ui/components/List/ListItem';
import ListItemText from 'yoda-ui/components/List/ListItemText';
import Tooltip from 'yoda-ui/components/Tooltip';

const TooltipList: FC<TooltipListProps> = ({ children, listItems, tooltipProps }) => {
  const { open, handleTooltipToggle, buttonRef } = useTooltipList();

  if (!children) return null;

  return (
    <Tooltip
      { ...tooltipProps }
      open={ open }
      arrow
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={
        <List>
          {
            listItems?.map((item: string, index: number) => (
              <ListItem key={ index }>
                <ListItemText primary={ item } sx={ listItemStyle } />
              </ListItem>
            ))
          }
        </List>
      }
    >
      <Box ref={ buttonRef } onClick={ handleTooltipToggle }>
        { children }
      </Box>
    </Tooltip>
  );
};

export default TooltipList;
