import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { tacticFragment } from '../fragments/tactic.fragments';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { MutationReturnType } from 'basics/types/maya.types';
import { BulkUpdateTacticInput, Tactic } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export type BulkUpdateTacticResponse = {
  bulkUpdateTactic: Tactic[];
};

export type UseBulkUpdateTacticMutation = [
  (
    cepId: string,
    bulkTacticInput: BulkUpdateTacticInput[],
    sendToVeeva?: boolean,
  ) => Promise<MutationReturnType<Tactic[]>>,
  MutationResult<BulkUpdateTacticResponse>,
];

export const BulkUpdateTacticMutation = gql`
mutation BulkUpdateTactic($bulkTacticInput: [BulkUpdateTacticInput!]!, $cepId: String!, $sendToVeeva: Boolean!) {
  bulkUpdateTactic(bulkTacticInput: $bulkTacticInput, cepId: $cepId, sendToVeeva: $sendToVeeva){
    ${tacticFragment}
  }
}`;

export const useBulkUpdateTacticMutation = (): UseBulkUpdateTacticMutation => {
  const [bulkUpdateTactic, state] = useMutation<BulkUpdateTacticResponse>(BulkUpdateTacticMutation, {
    fetchPolicy: 'network-only',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  const executeMutation = useCallback(async (cepId, bulkTacticInput, sendToVeeva = false) => {
    try {
      const { data } = await bulkUpdateTactic({
        variables: {
          bulkTacticInput,
          cepId,
          sendToVeeva,
        },
      });

      return { data: data?.bulkUpdateTactic || null, error: null };
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'bulkUpdateTactic.ts - useBulkUpdateTacticMutation',
        });

        return { data: null, error: customContext.message as string };
      }

      return { data: null, error: null };
    }
  }, [bulkUpdateTactic]);

  return [executeMutation, state];
};
