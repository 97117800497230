import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { cepFormStepIndexer } from './CepForm.states';
import { mayaCepListRoute, mayaCepTemplateListRoute } from 'basics/constants/routes.constants';
import { CepStatus, CepSteps, VeevaSyncStatus } from 'basics/enums/maya.enums';
import { useSetCepStatusToApprovedMutation } from 'basics/graphql/mutations/setCepStatusToApproved';
import { useSetCepStatusToFeedbackMutation } from 'basics/graphql/mutations/setCepStatusToFeedback';
import { useSyncTargetsToVeevaMutation } from 'basics/graphql/mutations/syncTargetsToVeeva';
import useGetCepByIdLazyQuery from 'basics/graphql/queries/getCepByIdLazy';
import { cepToBE } from 'basics/transformers/Cep.transformer';
import { CepStateType } from 'basics/types/maya.types';
import { succesToast, warningToast } from 'basics/utils/toast';
import { CepFormStepsIndex, CepFormSubmitCallbackType } from 'components/CepForm/CepForm.types';
import { CustomerEngagementPlanning } from 'generated/maya.types';
import { useQueryParams } from 'hooks/useQueryParams';
import useStepIndexer from 'hooks/useStepIndexer';
import cepState from 'states/cep.states';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCepFormActions = (yodaFormMethods: any, submitCallback: CepFormSubmitCallbackType, isTemplate: boolean) => {
  const { t } = useTranslation();
  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const stepIndexer = useStepIndexer(cepFormStepIndexer);
  const { stepIndex, moveNextStep, movePreviousStep, moveToStep } = stepIndexer;
  const { getValues, formState, setStepShowError } = yodaFormMethods;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { cepSourceId } = useQueryParams();
  const [setCepStatusToApproved] = useSetCepStatusToApprovedMutation();
  const [setCepStatusToFeedback] = useSetCepStatusToFeedbackMutation();
  const [syncTargetsToVeeva, targetsSyncState] = useSyncTargetsToVeevaMutation();
  const { queryGetCepById } = useGetCepByIdLazyQuery();
  const flags = useFlags();

  const setShowError = useCallback(
    (stepIndexVal: number) => {
      const step = CepFormStepsIndex[stepIndexVal];
      setStepShowError(true, [step]);
    },
    [setStepShowError],
  );

  const handleSaveData = useCallback(async () => {
    if (formState.isDirty) {
      setIsSubmitting(true);

      const customerEngagementPlanningInput = getValues();
      if (customerEngagementPlanningInput) {
        const formattedCEP = cepToBE(customerEngagementPlanningInput);
        const customerEngagementPlanningData = await submitCallback(formattedCEP);

        const { data: cep, error } = customerEngagementPlanningData;

        cepState.cep = cep || {};

        setIsSubmitting(false);
        if (cep) {
          succesToast(t('toast_draft_saved'));

          return cep;
        }

        warningToast(error || t('errors_standard'));

        return null;
      }
    } else {
      formState.showError = true;
      setShowError(stepIndex);
    }

    return cepStateValue.cep;
  }, [cepStateValue.cep, formState, getValues, setShowError, stepIndex, submitCallback, t]);

  const handleSyncTarget = useCallback(async () => {
    let targetsSynched = true;
    const isTargetsNotSynched = cepStateValue.targetList.filter((target) => target.veevaSyncStatus !== VeevaSyncStatus.SYNC);
    if (CepFormStepsIndex[stepIndex] === CepSteps.targetListStep && isTargetsNotSynched.length) {
      setIsSubmitting(true);
      const cepId = cepStateValue.cep?._id;

      if (cepId) {
        const { data: syncTarget } = await syncTargetsToVeeva(cepId);
        targetsSynched = Boolean(syncTarget?.syncVeeva);

        if (syncTarget && Array.isArray(syncTarget?.errors)) {
          syncTarget.errors.forEach((error) => {
            warningToast(error);
          });
        }

        const { data: customerEngagementPlanning } = await queryGetCepById({ variables: { id: cepId } });
        if (cepState.cep) {
          cepState.cep.globalCepSyncStatus = (customerEngagementPlanning as CustomerEngagementPlanning).globalCepSyncStatus;
        }
      }

      if (targetsSynched) {
        cepState.targetList = [];
      }

      setIsSubmitting(false);
    }

    return targetsSynched;
  }, [cepStateValue.cep?._id, cepStateValue.targetList, queryGetCepById, stepIndex, syncTargetsToVeeva]);

  const handlePublishEvent = useCallback(async () => {
    if (formState.isValid) {
      const customerEngagementPlanInput = getValues();
      if (customerEngagementPlanInput) {
        const cep = await handleSaveData();
        const cepStatus = cep?.status || cepState.cep?.status;
        const cepId = cep?._id || cepState.cep?._id;

        setIsSubmitting(true);
        if (cepStatus && cepId && cepStatus === CepStatus.DRAFT) {
          const { data: cepFeedback, error: cepFeedbackError } = await setCepStatusToFeedback(cepId);
          if (cepFeedback && !cepFeedbackError) {
            succesToast(t('maya_action_feedback_cep_success'));
            navigate(mayaCepListRoute);
          } else {
            warningToast(cepFeedbackError || t('maya_action_feedback_cep_error'));
          }
        }

        if (cepStatus && cepId && cepStatus === CepStatus.FEEDBACK) {
          const { data: cepApproved, error: cepApprovedError } = await setCepStatusToApproved(cepId);
          if (cepApproved && !cepApprovedError) {
            succesToast(t('maya_action_approve_cep_success'));
            navigate(mayaCepListRoute);
          } else {
            warningToast(cepApprovedError || t('maya_action_approve_cep_error'));
          }
        }

        setIsSubmitting(false);
      }
    }
  }, [formState.isValid, getValues, handleSaveData, setCepStatusToFeedback, t, navigate, setCepStatusToApproved]);

  const handleChangeStep = useCallback(
    (stepIndexVal: number) => {
      const previousStep = stepIndexer.previousStep || 0;
      let targetStep = stepIndexVal;
      if (!flags.mayaBiogenlinc4793EnableGoalStep && stepIndexVal >= CepFormStepsIndex.goalStep) {
        targetStep += 1;
      }
      stepIndexer.moveToStep(targetStep);
      setShowError(previousStep);
    },
    [flags.mayaBiogenlinc4793EnableGoalStep, setShowError, stepIndexer],
  );

  const enableDuplicateSave = useCallback(() => {
    if (stepIndex === CepFormStepsIndex.configurationStep && cepSourceId) {
      formState.isDirty = true;
    }
  }, [formState, stepIndex, cepSourceId]);

  const saveAndNext = useCallback(async () => {
    enableDuplicateSave();
    const cep = await handleSaveData();
    const targetsSynched = await handleSyncTarget();
    setShowError(stepIndex);

    if (cep && targetsSynched) {
      if (!flags.mayaBiogenlinc4793EnableGoalStep && stepIndex + 1 === CepFormStepsIndex.goalStep) {
        moveToStep(CepFormStepsIndex.goalStep + 1);
      } else {
        moveNextStep();
      }
    }
  }, [
    enableDuplicateSave,
    flags.mayaBiogenlinc4793EnableGoalStep,
    handleSaveData,
    handleSyncTarget,
    moveNextStep,
    moveToStep,
    setShowError,
    stepIndex,
  ]);

  const saveAndPrevious = useCallback(() => {
    handleSaveData();
    setShowError(stepIndex);

    if (!flags.mayaBiogenlinc4793EnableGoalStep && stepIndex - 1 === CepFormStepsIndex.goalStep) {
      moveToStep(CepFormStepsIndex.goalStep - 1);
    } else {
      movePreviousStep();
    }
  }, [flags.mayaBiogenlinc4793EnableGoalStep, handleSaveData, movePreviousStep, moveToStep, setShowError, stepIndex]);

  const saveAsDraft = useCallback(() => {
    handleSaveData();
    setShowError(stepIndex);
    if (isTemplate) {
      navigate(mayaCepTemplateListRoute);
    } else {
      navigate(mayaCepListRoute);
    }
  }, [handleSaveData, isTemplate, navigate, setShowError, stepIndex]);

  return {
    handleChangeStep,
    handlePublishEvent,
    handleSaveData,
    saveAsDraft,
    setShowError,
    stepIndexer,
    saveAndNext,
    saveAndPrevious,
    syncTargetsLoading: targetsSyncState.loading,
    isSubmitting,
  };
};

export default useCepFormActions;
