import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { YodaColors, YodaSpacing } from 'yoda-ui/yodaTheme';

export const TacticCardWrapperStyle: SxProps<Theme> = {
  padding: '1rem',
  border: 'none',
  boxShadow: `0px 5px 20px ${YodaColors.gray2}`,
};

export const TacticKanbanStyle: SxProps<Theme> = {
  marginTop: YodaSpacing.medium,
  paddingBottom: YodaSpacing.medium,
  display: 'flex',
  overflowX: 'auto',
  overflowY: 'hidden',
  maxWidth: '66vw',

  '& > *': {
    marginLeft: YodaSpacing.small,
    marginRight: YodaSpacing.small,
    minWidth: '260px',
  },
};

export const TacticCardListStyle: SxProps<Theme> = {
  '& > *': {
    maxWidth: '260px',
    marginBottom: YodaSpacing.small,
  },
  '& > *:hover': {
    backgroundColor: YodaColors.blue1,
    boxShadow: `0 0 7px 1px ${YodaColors.gray2}`,
  },
};
