import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useCepDuplicate from './CepDuplicate.hook';
import { VeevaSyncStatus } from 'basics/enums/maya.enums';
import CepForm from 'components/CepForm/CepForm';
import CiDialog from 'components/CiDialog';
import Heading from 'components/Heading';
import MayaLayout from 'components/MayaLayout';
import VeevaStatusBar from 'components/VeevaStatusBar/VeevaStatusBar';
import Loader from 'yoda-ui/components/Loader';

const CepDuplicate: FC = () => {
  const { t } = useTranslation();
  const {
    submitCallback,
    cepStateValue,
    eventStateValue,
    onClickNextCustomerInitiative,
    onClickNewCustomerInitiative,
    isOpenCIDialog,
    loadingEvent,
    eventId,
  } = useCepDuplicate();

  return (
    <MayaLayout>
      <Heading>
        { t('maya_duplicate_cep_form_title') }
        {
          cepStateValue.cep?._id
            ? (<VeevaStatusBar
              lastVeevaSync={ cepStateValue.cep?.lastVeevaSync }
              lastVeevaSyncErrors={ cepStateValue.cep?.globalCepSyncStatus?.errors }
              veevaSyncStatus={ cepStateValue.cep?.globalCepSyncStatus?.syncStatus as VeevaSyncStatus }
            />)
            : null
        }
      </Heading>
      {
        eventId && loadingEvent
          ? <Box display={ 'flex' } flex={ 1 } justifyContent={ 'center' } alignItems={ 'center' }><Loader center></Loader></Box>
          : <>
            <CepForm submitCallback={ submitCallback } isTemplate={ false }/>

            <CiDialog
              onClickNew={ onClickNewCustomerInitiative }
              onClickNext={ onClickNextCustomerInitiative }
              open={ isOpenCIDialog }
              dateRange={ eventStateValue.event.dataRange }
            ></CiDialog>
          </>
      }
    </MayaLayout>
  );
};

export default CepDuplicate;
